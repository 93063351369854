import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Header, Footer, HCPPopup } from "./components";
import { useLocation } from "react-router-dom";
import {
  Home,
  ContactUs,
  InitiatingFenhuma,
  Resources,
  AssessmentReport,
  PI,
} from "./pages/hcp";

export default function HCP() {
  const location = useLocation();

  const HCPStatus = useSelector((state) =>
    location.pathname === "/hcp/pi" ? true : state.value
  );

  const body = document.querySelector("body");
  if (
    !body.classList.contains("site-hcp") ||
    !body.classList.contains("site-patient") ||
    !body.classList.contains("site-root")
  ) {
    if (location.pathname === "/hcp/pi") {
      body.classList.add("site-hcp");
    }
  }

  useEffect(() => {

    if(window.location.hash) {
      var target = window.location.hash.substring(1);
      var targetElement = document.getElementById(target);

      if(targetElement){
        targetElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }

    }

  }); // <-- empty array means 'run once'

  const load = () => {
    return !HCPStatus ? (
      <HCPPopup />
    ) : (
      <>
        <Header />
        <Routes>
          <Route path="/hcp/contact-us" element={<ContactUs />}></Route>
          <Route path="/hcp/initiating-fenhuma" element={<InitiatingFenhuma />}></Route>
          <Route path="/hcp/resources" element={<Resources />}></Route>
          <Route
            path="/hcp/public-assessment-report"
            element={<AssessmentReport />}
          ></Route>
          <Route path="/hcp/pi" element={<PI />}></Route>
          <Route path="/hcp/" element={<Home />}></Route>
        </Routes>
        <Footer />
      </>
    );
  };

  return load();
}
