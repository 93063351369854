import React from "react";
import prescribeImage from "../../../img/prescribe-fenhuma.png";

export default function SectionPrescribe() {
  return (
    <section className="section section--prescribe section--grey section--half-half prescribe">
      <div className="inner-container inner-container--large">
        <div className="left">
          <img src={prescribeImage} alt="A couple are embracing and feeling peaceful" />
        </div>
        <div className="right">
          <h2>
            Prescribe Fenhuma<sup>&reg;</sup> for your adult patients using
            opioid therapy for their chronic cancer pain, who need additional
            pain relief to control episodes of breakthrough pain<sup>1</sup>
          </h2>
          <p>
            If a patient is suffering from transient exacerbations of malignant pain, the
            first action should be to assess whether their background pain is
            adequately controlled. Options to consider include:<sup>4</sup>
          </p>
          <ul>
            <li>Increasing the dose of the background pain medication</li>
            <li>Changing the medication</li>
            <li>Adding another medication to the existing one</li>
            <li>Explore non-pharmacological treatments</li>
          </ul>
          <p>
            If, after these options have been fully explored, the patient still
            suffers from transient exacerbations of pain, they may be suffering
            from{" "}
            <span className="nowrap">
              <strong>breakthrough pain</strong>.<sup>4</sup>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}
