import React from "react";
import {
  Heading,
  Resource,
  References,
  ExternalLink,
  PatientSmallHeading,
} from "../../../components";
import { downloads, links } from "./resource-data";

export default function Resources() {
  let headingCopy = function (props) {
    return (
      <>
        <p style={{ "margin-bottom": "2rem" }}>
          <strong>
            We support you to manage your episodes of breakthrough cancer pain that are
            not controlled by your background opioid therapy.
          </strong>
        </p>
        <p style={{ "margin-bottom": "2rem" }}>
          You can use these resources to help you manage your pain, as well as the{" "}
          <ExternalLink
            className="nowrap"
            link="https://www.medicines.org.uk/emc/rmm/2514/Document"
          >
            Patient & Carer Guide
          </ExternalLink>
          .
        </p>
        <p>
          It is important to read the product package leaflet provided with your medicine.
          This information is not a substitute for the product package leaflet. The
          package leaflet contains additional important information on the use of the
          medicine
        </p>
      </>
    );
  };

  return (
    <main>
      <PatientSmallHeading
        tagline="Resources"
        title="Control your episodes of breakthrough pain"
        copyComponent={headingCopy}
      />

      <section className="section section--beige section--resources">
        <div className="resource-container">
          <h2>Risk Minimisation Materials</h2>
          <Resource key={1} data={downloads[0]} type="link" />
        </div>
      </section>

      <section className="section section--white section--resources">
        <div className="resource-container">
          <h2>Our Downloads</h2>
          <Resource key={2} data={downloads[1]} type="download" />
        </div>
      </section>

      <section className="section section--beige section--resources pointer pointer--top pointer--left-50 pointer--pull-up">
        <div className="resource-container">
          <h2>External Links</h2>
          {links.map((link, idx) => {
            return <Resource key={idx} data={link} type="link" />;
          })}
        </div>
      </section>

      <section
        className="pointer pointer--top pointer--full"
        style={{ height: "8px" }}
      ></section>
    </main>
  );
}
