import React from "react";
import {
  PatientSmallHeading,
  References,
  ExternalLink,
  PatientLargeHeading as LargeHeading,
} from "../../../components";

import HomeHeader from "../../../img/taking-fenhuma-hero@2x.png";
import Pill100 from "../../../img/packshots/Fenhuma-Packshot_100mg_1.png";
import Pill200 from "../../../img/packshots/Fenhuma-Packshot_100mg_2.png";
import Pill300 from "../../../img/packshots/Fenhuma-Packshot_100mg_3.png";
import Pill400 from "../../../img/packshots/Fenhuma-Packshot_100mg_4.png";
import Pill600 from "../../../img/packshots/Fenhuma-Packshot_100mg_5.png";
import Pill800 from "../../../img/packshots/Fenhuma-Packshot_100mg_6.png";
import RightDoseImg from "../../../img/patient-right-dose@2x.png";
import AlertIcon from "../../../img/patient-alert-icon.svg";
import ConsumePillOneImg from "../../../img/patient-consume-one@2x.png";
import ConsumePillTwoImg from "../../../img/patient-consume-two@2x.png";
import { NavLink } from "react-router-dom";

export default function How() {
  return (
    <main className="page--how">
      <LargeHeading
        classes="taking-fenhuma-hero"
        image={HomeHeader}
        alt="A man is closing his eyes and looking peaceful. A woman is looking out of the window with her head in her hands"
        tagline="How do I take Fenhuma<sup>®</sup>?"
        title="Taking Fenhuma<sup>&reg;</sup>"
        body={[
          "<p class='right-dose'>Finding the right dose</p><p>Before you start taking Fenhuma<sup>®</sup>, your doctor will explain how Fenhuma<sup>®</sup> should be taken to treat your breakthrough pain. They will also explain how long the course of treatment will last and arrange a plan for stopping treatment.<sup>1</sup></p><p style='margin-top: 1rem; margin-bottom: 2rem'>It is important to read the product package leaflet provided with your medicine. This information is not a substitute for the product package leaflet. The package leaflet contains additional important information on the use of the medicine.</p><p style='margin-top: 1rem; margin-bottom: 2rem'>Always check with your doctor or pharmacist if you are unsure of your treatment plan.<sup>1</sup></p>",
        ]}
        link={
          <NavLink
            className="btn btn--patient"
            to="/patient/why-have-i-been-prescribed-fenhuma"
          >
            Why have I been prescribed Fenhuma<sup>®</sup>?
          </NavLink>
        }
      />

      <section className="section section--grey section--half-half pill-strengths">
        <div className="container">
          <div className="left">
            <h2>
              Fenhuma<sup>®</sup> is available in different strengths
            </h2>

            <p>
              When starting treatment, you may need to try a range of strengths over
              several breakthrough pain episodes to find the most appropriate dose.
              <sup>1</sup>
            </p>
            <p>
              Each strength of Fenhuma<sup>®</sup> has a different shape and colour-coded
              box to avoid confusion.<sup>2</sup>
            </p>
            <p>
              You may need to take more than one Fenhuma<sup>®</sup> tablet at a time,
              and/or combine different strengths to reach the final dose recommended by
              your doctor.<sup>1</sup>
            </p>
            <p>
              Your doctor will check in with you regularly when you start Fenhuma
              <sup>®</sup> to ensure you are happy with the treatment, and to lower your
              risk of getting side effects. They will make sure you understand which
              tablets to take moving forwards. You should only ever take the dose your
              doctor tells you to.<sup>1</sup>
            </p>
          </div>
          <div className="right">
            <div className="grid grid-3-cols">
              <div className="pill-wrapper">
                <div className="pill yellow">
                  <img src={Pill100} alt="" />
                </div>
                100 Micrograms
              </div>
              <div className="pill-wrapper">
                <div className="pill blue">
                  <img src={Pill200} alt="" />
                </div>
                200 Micrograms
              </div>
              <div className="pill-wrapper">
                <div className="pill green">
                  <img src={Pill300} alt="" />
                </div>
                300 Micrograms
              </div>
              <div className="pill-wrapper">
                <div className="pill orange">
                  <img src={Pill400} alt="" />
                </div>
                400 Micrograms
              </div>
              <div className="pill-wrapper">
                <div className="pill pink">
                  <img src={Pill600} alt="" />
                </div>
                600 Micrograms
              </div>
              <div className="pill-wrapper">
                <div className="pill red">
                  <img src={Pill800} alt="" />
                </div>
                800 Micrograms
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section section--white section--half-half pointer pointer--top pointer--right-50 found-right-dose">
        <div className="container">
          <div className="left">
            <img src={RightDoseImg} alt="A woman is looking into the distance" />
          </div>
          <div className="right">
            <h2>
              Once you've found the right dose<sup>1</sup>
            </h2>

            <p>
              Once you and your doctor have found a dose that provides you with adequate
              breakthrough pain relief, this will become your 'maintenance dose'.
            </p>

            <ul>
              <li>
                You should take this dose no more than <strong>four times a day</strong>
              </li>
              <li>
                You must leave <strong>at least two hours</strong> between doses of
                Fenhuma<sup>®</sup>
              </li>
            </ul>
            <p>
              You must <strong>always</strong> use the dose of Fenhuma® prescribed by your
              doctor – this may be different from the dose that you have previously used
              for treating <span className="nowrap">breakthrough cancer pain</span>.
            </p>

            <p>
              If you feel that the dose of Fenhuma® that you are using is not controlling
              your breakthrough pain very well, tell your doctor as they may need to
              change <span className="nowrap">your dose</span>.
            </p>
          </div>
        </div>
      </section>

      <section className="section section--grey section--half-half pointer pointer--top pointer--left-50 taking-the-medication pointer--pull-up">
        <div className="container">
          <div className="left">
            <h2>Taking the medicine</h2>

            <p>
              Fenhuma<sup>®</sup> is a sublingual opioid tablet. This means that the
              tablet is placed under your tongue, where it dissolves quickly to allow the
              active ingredient, fentanyl, to be absorbed into the body and provide
              breakthrough pain relief.<sup>1</sup>
            </p>

            <ol>
              <li>
                If your mouth is dry, take a sip of water to moisten it. Spit out or
                swallow <span className="nowrap">the water</span>
              </li>
              <li>
                Remove the tablet(s) from the blister pack immediately before use
                <ul type="disc">
                  <li>
                    Separate one blister square from the pack by tearing along the dotted
                    line, leaving the rest of the tablets together
                  </li>
                  <li>
                    Peel back the edge of the foil where the arrow is shown and gently
                    remove the tablet
                  </li>
                  <li>
                    Do not try to push Fenhuma<sup>®</sup> tablets through the foil top,
                    as this will damage them
                  </li>
                </ul>
              </li>
              <li>
                Place the tablet under your tongue as far back as you can and let it{" "}
                <span className="nowrap">dissolve completely</span>
              </li>
            </ol>
          </div>
          <div className="right">
            <div className="box">
              <img src={AlertIcon} width="48" alt="" />
              <p>
                It is important that you <strong>do not suck, chew or swallow</strong> the
                tablet. <br /> You should <strong>not drink or eat</strong> anything until
                the tablet has completely dissolved under your tongue.<sup>1</sup>
              </p>
            </div>
            <div className="grid grid-2-cols">
              <img src={ConsumePillOneImg} alt="" />
              <img src={ConsumePillTwoImg} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section section--white section--full-width taken-wrong-dose">
        <div className="container">
          <h2>
            Taken the wrong dose of Fenhuma<sup>®</sup>?
          </h2>
          <p>Visit our side effects page to find out what you need to do next</p>

          <NavLink className="btn btn--patient" to="/patient/side-effects">
            Side effects of Fenhuma<sup>®</sup>
          </NavLink>
        </div>
      </section>

      <References>
        <ol>
          <li>
            Fenhuma Patient Information Leaflet (PIL). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/files/pil.13972.pdf"
              title="https://www.medicines.org.uk/emc/files/pil.13972.pdf"
            />
          </li>
          <li>
            Fenhuma Patient & Carer Guide: Important risk minimisation information for
            patients and their carers. Available at{" "}
            <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2514/Document">
              https://www.medicines.org.uk/emc/rmm/2514/Document
            </ExternalLink>
          </li>
        </ol>
      </References>
    </main>
  );
}
