import React from "react";
import {
  PatientLargeHeading as LargeHeading,
  CTA,
  References,
  ExternalLink,
} from "../../../components";
import SEHeader from "../../../img/patient-side-effects@2x.png";
import SideEffectsImg from "../../../img/patient-side-effects-graphic.png";
import SleepRelatedImg from "../../../img/patient-sleep-related.png";
import PregnancyImg from "../../../img/fenhuma-and-pregnancy.png";

import AlertIcon from "../../../img/patient-alert-icon.svg";
import PillsIcon from "../../../img/patient-icon-pills.svg";
import SleepIcon from "../../../img/patient-sleep-icon.svg";
import FoodIcon from "../../../img/patient-icon-drink.svg";
import CogIcon from "../../../img/patient-icon-cog.svg";
import BabyIcon from "../../../img/patient-baby-icon.svg";
import DownloadIcon from "../../../img/download-icon-white.svg";

export default function Why() {
  let headingComponent = function (props) {
    return (
      <>
        <p style={{ "margin-bottom": "2rem" }}>
          As with all medicines, Fenhuma<sup>®</sup> can cause side effects, although not
          everybody will get them.{" "}
        </p>
        <p style={{ "margin-bottom": "2rem" }}>
          It is important to read the product package leaflet provided with your medicine.
          This information is not a substitute for the product package leaflet. The
          package leaflet contains additional important information on the use of the
          medicine.
        </p>
        <p>
          Scroll down to find out more about the side effects associated with Fenhuma
          <sup>®</sup>.
        </p>
      </>
    );
  };

  return (
    <main>
      <LargeHeading
        image={SEHeader}
        tagline="Side effects"
        alt="A middle-aged woman is lying her head on her partner's chest"
        title="Managing the side<br />effects of Fenhuma<sup>&reg;</sup>"
        body=""
        bodyComponent={headingComponent}
      />

      <section className="section section--grey section--full-width">
        <div className="container">
          <h2>Important things you should know about fentanyl</h2>
          <p>
            Fenhuma<sup>®</sup> contains an opioid called fentanyl (or fentanil), which
            can cause addiction. <br /> You can get withdrawal symptoms if you stop taking
            it suddenly.<sup>1,2</sup>
          </p>
        </div>
      </section>

      <section className="section section--white section--half-half pointer pointer--top pointer--left-50 se-reported-se">
        <div className="container">
          <div className="left">
            <h2>
              Reported side effects<sup>1</sup>
            </h2>

            <p>
              If you start to feel unusually or extremely sleepy or your breathing becomes
              slow or shallow, you or your carer should{" "}
              <strong>
                immediately contact your doctor or local hospital for emergency help
              </strong>
              .
            </p>
            <p className="full-opacity">
              <strong style={{opacity: "1"}}>
                Very common (may affect more than 1 in 10 people)
              </strong>{" "}
              <br/>
              <span style={{opacity: 0.8}}>Nausea</span>
            </p>

            <p className="full-opacity" style={{marginBottom: "0px", lineHeight: 0}}>
              <strong>Common (may affect up to 1 in 10 people)</strong>
            </p>

            <p style={{marginTop: 0}}>
              Dizziness, headache, excessive sleepiness, breathlessness/shortness of
              breath, inflammation inside the mouth, vomiting, constipation, dry mouth,
              sweating, weary/tired/lack of energy.
            </p>

            <p>
              Inform your doctor if you experience pain or increased sensitivity to pain
              (hyperalgesia) which does not respond to a higher dosage of your medicine as
              prescribed by your doctor.
            </p>
            <p>
              Tolerance, dependence, and addiction may develop upon
              repeated administration of opioids such as fentanyl. Repeated use of Fenhuma
              <sup>®</sup> may lead to Opioid Use Disorder. Abuse or intentional misuse of
              Fenhuma<sup>®</sup> may result in overdose and/or death.
            </p>

            <p>
              You may have a greater risk of becoming dependent or addicted on Fenhuma<sup>&reg;</sup> if:
            </p>

            <ul>
              <li>You or anyone in your family have ever abused or been dependent on alcohol, prescription medicines or
                illegal drugs (“addiction”)
              </li>
              <li>
                You are a smoker
              </li>
              <li>
                You have ever had problems with your mood (depression, anxiety or a personality disorder) or have been
                treated by a psychiatrist for other mental illness
              </li>
            </ul>

            <p>
              <strong>Tell your doctor if you notice any of the following signs of dependence or addiction whilst taking
                Fenhuma<sup>&reg;</sup>:</strong>
            </p>

            <ul>
              <li>
                You need to use the medicine for longer than advised by your doctor
              </li>
              <li>
                You need to use more than the recommended dose
              </li>
              <li>
                You are using the medicine for reasons other than prescribed, for instance, ‘to stay calm’ or ‘help you
                sleep’
              </li>
              <li>
                You have made repeated, unsuccessful attempts to quit or control the use of the medicine
              </li>
              <li>
                When you stop taking the medicine you feel unwell (e.g, nausea, vomiting, diarrhoea, anxiety, chills,
                tremor, and sweating), and you feel better once using the medicine again
              </li>

            </ul>

            <p>
              Your doctor may discuss when it is appropriate to stop taking Fenhuma<sup>&reg;</sup> and how to stop safely.
            </p>
          </div>
          <div className="right">
            <img
                src={SideEffectsImg}
                alt="A woman is looking into the distance. A man is closing his eyes and contemplating"
            />
          </div>
        </div>
      </section>

      <section className="section section--grey section--half-half what-if-i-take-more">
        <div className="container">
          <div className="left">
            <div className="box">
              <img src={AlertIcon} alt="" />

              <p>
                <strong>Symptoms of overdose include:</strong>
              </p>
              <p>
                Extreme drowsiness <br />
                Slow, shallow breathing <br />
                Coma
              </p>
              <p>
                An overdose may also result in a brain disorder known as <span style={{whiteSpace:"nowrap"}}>toxic leukoencephalopathy</span>
              </p>
              <p>
                If these occur, <strong>seek medical help immediately</strong>
              </p>
            </div>
          </div>
          <div className="right">
            <h2>
              What if I take more Fenhuma<sup>®</sup>{" "}
              <span className="nowrap">
                than I should have?<sup>1</sup>
              </span>
            </h2>
            <p>
              If you realise you have taken more Fenhuma<sup>®</sup> than directed by your
              doctor, you should:
            </p>

            <ul>
              <li>Remove any remaining tablets from your mouth</li>
              <li>Tell your carer or another person what has happened</li>
              <li>
                You or your carer should immediately contact your doctor, pharmacist or
                local hospital to discuss what action to take
              </li>
              <li>
                Ensure you try your best to stay awake – if with your carer or another
                person, ask them to keep you awake by talking to you or shaking you now
                and then
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section  section--white pointer pointer--top pointer--right-50 alongside-other-meds">
        <div className="container">
          <img src={PillsIcon} alt="" />
          <h2>
            Taking Fenhuma<sup>®</sup> alongside other medicines<sup>1</sup>
          </h2>
          <p>
            Make sure you tell your doctor or pharmacist about any other medicines you are
            taking, as they will be able to assess your risk of experiencing side effects
            and ensure that Fenhuma<sup>®</sup> is the right treatment for you. Certain
            medicines may change how well Fenhuma<sup>®</sup> works for you.
          </p>

          <div className="grid grid-2-cols">
            <div>
              <p className="full-opacity">
                <strong>
                  Tell your doctor if you are taking or have recently taken any of the
                  following:
                </strong>
              </p>

              <ul>
                <li>Antifungal medicines containing ketoconazole or itraconazole</li>
                <li>Antibiotic medicines called macrolides</li>
                <li>Antiviral medicines called protease inhibitors</li>
                <li>Rifampin or rifabutin (used to treat bacterial infections)</li>
                <li>
                  Carbamazepine, phenytoin or phenobarbital (medicines used to treat
                  convulsions/fits)
                </li>
                <li>Herbal medicines containing St John’s wort</li>
                <li>Medicines containing alcohol</li>
                <li>
                  Medicines called monoamine oxidase (MAO) inhibitors, which are used for
                  severe depression and Parkinson's disease. Tell your doctor if you have
                  taken this type of medicine within the last two weeks
                </li>
                <li>
                  Certain types of strong pain killers, called partial
                  agonist/antagonists, e.g, buprenorphine, nalbuphine and pentazocine
                  (medicines for treatment of pain)
                </li>
              </ul>

              <p>
                Your Fenhuma<sup>®</sup> dose may need to change if you take any of these
                medications, so ensure your doctor is kept up to date if you start any new
                treatments.
              </p>
            </div>
            <div>
              <p className="full-opacity">
                <strong>
                  Fenhuma<sup>®</sup> may add to the effect of medicines that make you
                  feel sleepy, including:
                </strong>
              </p>

              <ul>
                <li>Other strong pain-relieving medicines (opioid-type medicines)</li>
                <li>General anaesthetics (used to make you sleep during operations)</li>
                <li>Muscle relaxants</li>
                <li>Sleeping tablets</li>
                <li>
                  Medicines used to treat depression, allergies and/or anxiety and
                  psychosis
                </li>
                <li>
                  Medicines containing clonidine (used to treat high blood pressure)
                </li>
              </ul>

              <p>
                Taking Fenhuma<sup>®</sup> at the same time as medicines that make you
                feel sleepy increases the risk of:
              </p>
              <ul>
                <li>Drowsiness</li>
                <li>Difficulty breathing</li>
                <li>Coma</li>
              </ul>

              <p>
                Please tell your doctor about all sedative medicines you are taking and
                make sure you follow your doctors dose recommendation closely. It could be
                helpful to inform friends or relatives to be aware of the signs and
                symptoms stated above – if you experience drowsiness or find it hard to
                breathe, make sure you tell your doctor.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section section--half-half pointer pointer--top pointer--left-50 pointer--pull-up sleep-related">
        <div className="container">
          <div className="left">
            <img
              src={SleepRelatedImg}
              width={551}
              alt="An elderly man is looking into the distance"
            />
          </div>
          <div className="right">
            <img src={SleepIcon} alt="" />
            <h2>
              Fenhuma<sup>®</sup> and sleep-related{" "}
              <span className="nowrap">breathing disorders</span>
              <sup>1</sup>
            </h2>

            <p>
              Fenhuma<sup>®</sup> can cause sleep-related breathing disorders such as
              sleep apnoea (breathing pauses during sleep) and sleep-related hypoxemia
              (low oxygen level in the blood). The symptoms can include:
            </p>
            <ul>
              <li>Breathing pauses during sleep</li>
              <li>Waking at night due to shortness of breath</li>
              <li>Difficulties in staying asleep</li>
              <li>Feeling very tired during the day</li>
            </ul>

            <p>If you or another person notice these symptoms, contact your doctor.</p>
          </div>
        </div>
      </section>

      <section className="section section--half-half section--white food-and-driving">
        <div className="container">
          <div className="left">
            <img src={FoodIcon} alt="" />
            <h2>
              Fenhuma<sup>®</sup> with food, drink and alcohol<sup>1</sup>
            </h2>
            <p>
              Taking Fenhuma<sup>®</sup> can make some people feel tired. Do not drink
              alcohol without talking to your doctor first, as it may make you feel more
              tired than usual.
            </p>
            <p>
              Do not drink grapefruit juice when taking Fenhuma<sup>®</sup> as it could
              increase the number of side effects that you get.
            </p>
          </div>
          <div className="right">
            <img src={CogIcon} alt="" />
            <h2>
              Driving and using machines when taking Fenhuma<sup>®1</sup>
            </h2>
            <p>
              Fenhuma<sup>®</sup> may make it difficult for you to carry out potentially
              dangerous tasks, such as driving or operating machinery.
            </p>

            <p>
              If you feel dizzy, sleepy or have blurred vision when taking Fenhuma
              <sup>®</sup>, do not drive or use machinery. If you are concerned about
              whether it is safe for you to drive, please talk to your doctor.
            </p>
          </div>
        </div>
      </section>

      <section className="section section--grey section--half-half pointer pointer--top pointer--right-50 pointer--pull-up pregnancy-section">
        <div className="container">
          <div className="left">
            <img src={BabyIcon} alt="" />
            <h2>
              Fenhuma<sup>®</sup> and pregnancy<sup>1</sup>
            </h2>

            <p>
              Do not take Fenhuma<sup>®</sup> if you are pregnant unless you have
              discussed this with your doctor. Fenhuma<sup>®</sup> could cause your baby
              to become dependent and experience withdrawal symptoms after birth.
            </p>

            <p>
              Do not take Fenhuma® while breastfeeding, as fentanyl will pass into the
              breast milk and affect your baby.
            </p>
          </div>
          <div className="right">
            <img
              src={PregnancyImg}
              alt="A woman is closing her eyes and looking peaceful"
            />
          </div>
        </div>
      </section>

      <section className="section section--white section--full-width download-summary">
        <h2>
          Download our easy-to-read summary of side effects associated with Fenhuma
          <sup>®</sup>
        </h2>

        <a
          target="_blank"
          href="https://kva-glenmark.s3.eu-west-2.amazonaws.com/fenhuma/patient/GLEN24392+-+Fenhuma+patient+website+download+Side+effects_Update_v0.3.pdf"
          className="btn btn--patient"
        >
          Summary of side effects <img src={DownloadIcon} alt="" />
        </a>
      </section>

      <References showDivider={true}>
        <ol>
          <li>
            Fenhuma Patient Information Leaflet (PIL). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/files/pil.13972.pdf"
              title="https://www.medicines.org.uk/emc/files/pil.13972.pdf"
            />
          </li>
          <li>
            Fenhuma Patient & Carer Guide: Important risk minimisation information for
            patients and their carers. Available at{" "}
            <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2514/Document">
              https://www.medicines.org.uk/emc/rmm/2514/Document
            </ExternalLink>
          </li>
        </ol>
      </References>
    </main>
  );
}
