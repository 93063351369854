import React, { useEffect, useState } from "react";

export default function LargeHeading({ image, tagline, title, body, alt , link, classes, bodyComponent}) {
  const [tagLinePointerTop, setTagLinePointerTop] = useState(0);
  const [tagLinePointerRight, setTagLinePointerRight] = useState(0);

  const positionTagLineCircle = () => {
    const taglineParent = document.querySelector(".heading-right");
    const taglinePointerParent = document.querySelector(".heading-left");
    const container = document.querySelector(".heading .container");
    const topPadding = 6;
    const rightPadding = 6;
    const headingImage = document.querySelector(".heading-left img");

    headingImage.addEventListener("load", () => {
      setTagLinePointerTop(
        (taglinePointerParent.offsetHeight - taglineParent.offsetHeight) / 2 +
          topPadding
      );

      setTagLinePointerRight(
        parseInt(
          window
            .getComputedStyle(container, null)
            .getPropertyValue("gap")
            .replace("px", "")
        ) /
          2 +
          rightPadding
      );
    });
  };

  useEffect(() => {
    positionTagLineCircle();
  }, []);

  return (
    <section className={`heading heading--large ${classes}`}>
      <div className="container">
        <div className="left heading-left">
          <div
            className="tagline-pointer tagline-pointer--home"
            style={{
              top: tagLinePointerTop,
              right: -tagLinePointerRight,
            }}
          ></div>
          <img src={image} alt={alt} />
        </div>
        <div className="right heading-right">
          <div
            className="tagline tagline--home"
            dangerouslySetInnerHTML={{ __html: tagline }}
          ></div>
          <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          <p dangerouslySetInnerHTML={{ __html: body }}></p>


          { link ? link : ""}
          { bodyComponent ? bodyComponent() : ""}

        </div>
      </div>
    </section>
  );
}
