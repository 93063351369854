import React from "react";
import { useSite } from "../../hooks";
import DownloadIcon from "../../img/download-icon-pink.svg";
import LinkIcon from "../../img/link-icon-pink.svg";
import PatientDownloadIcon from "../../img/patient-download-icon.svg";
import PatientLinkIcon from "../../img/patient-link-icon.svg";
import { ExternalLink } from "../../components/";
import { NavLink } from "react-router-dom";

export default function Resource(props) {
  const { currentSite } = useSite();

  const displayDownloadLinkIcon = () => {
    if (props.data.renderType && props.data.renderType === "component") {
      return currentSite === "hcp" ? (
        <ExternalLink link={props.data.link} className={props.data.trigger}>
          <img
            src={props.type === "download" ? DownloadIcon : LinkIcon}
            alt=" "
          />
        </ExternalLink>
      ) : (
        <ExternalLink link={props.data.link}>
          <img
            src={
              props.type === "download" ? PatientDownloadIcon : PatientLinkIcon
            }
            alt=" "
          />
        </ExternalLink>
      );
    } else {
      return currentSite === "hcp" ? (
        <img
          src={props.type === "download" ? DownloadIcon : LinkIcon}
          alt=" "
        />
      ) : (
        <img
          src={
            props.type === "download" ? PatientDownloadIcon : PatientLinkIcon
          }
          alt=" "
        />
      );
    }
  };

  const resourceInner = () => {
    return (
      <>
        <a
          href={props.data.link}
          target="_blank"
          rel="noreferrer"
          className="icon"
        >
          <img src={props.data.icon.type} alt={props.data.title} />
        </a>
        <div className="data">
          <a
            href={props.data.link}
            target="_blank"
            rel="noreferrer"
            className="title"
            dangerouslySetInnerHTML={{ __html: props.data.title }}
          ></a>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: props.data.description }}
          ></div>
        </div>
        <a
          href={props.data.link}
          target="_blank"
          rel="noreferrer"
          className="action-icon"
        >
          {props.type === "download" ? <span>Download</span> : ""}
          {displayDownloadLinkIcon()}
        </a>
      </>
    );
  };

  const externalLink = () => {
    return (
      <>
        <div className="icon">
          <img src={props.data.icon.type} alt={props.data.title} />
        </div>
        <div className="data">
          <ExternalLink link={props.data.link} className={props.data.trigger}>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: props.data.title }}
            ></div>
          </ExternalLink>

          {props.data.renderType && props.data.renderType === "component" && (
            <div className="description">{props.data.descriptionComp}</div>
          )}
          {!props.data.renderType && (
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: props.data.description }}
            ></div>
          )}
        </div>
        <div className="action-icon">
          {props.type === "download" ? <span>Download</span> : ""}
          {displayDownloadLinkIcon()}
        </div>
      </>
    );
  };

  const className = `resource ${props.data.trigger}`;

  if (props.type === "download") {
    return (
      <a target="_blank" href={props.data.link} className={className}>
        {resourceInner()}
      </a>
    );
  } else {
    if (props.data.renderType && props.data.renderType === "component") {
      return <div className={className}>{externalLink()}</div>;
    } else {
    }
    return (
      <ExternalLink link={props.data.link} className={className}>
        {externalLink()}
      </ExternalLink>
    );
  }
}
