import DownloadIcon1 from "../../../img/downloads-link1.svg";
import DownloadIcon2 from "../../../img/downloads-link2.svg";

import LinkIcon1 from "../../../img/icon-link1.svg";
import LinkIcon2 from "../../../img/icon-link2.svg";
import LinkIcon3 from "../../../img/icon-link3.svg";
import LinkIcon4 from "../../../img/icon-link4.svg";

export const downloads = [
  {
    title: "Risk Minimisation Materials for Patients and/or Carers",
    description:
      "Follow this link to access our Risk Minimisation Materials, which provide further information on what to expect from your treatment with Fenhuma<sup>®</sup> and answers common frequently asked questions.",
    icon: <DownloadIcon1 />,
    link: "https://www.medicines.org.uk/emc/rmm/2514/Document",
    trigger: "download-one",
  },
  {
    title: "Side effect summary",
    description:
      "Fenhuma<sup>®</sup> and other opioid therapies can lead to a range of side effects. Download this easy-to-read summary to find out about known side effects associated with taking Fenhuma® and how to report any side effects that you experience.",
    icon: <DownloadIcon2 />,
    link: "https://kva-glenmark.s3.eu-west-2.amazonaws.com/fenhuma/patient/GLEN24392+-+Fenhuma+patient+website+download+Side+effects_Update_v0.3.pdf",
    trigger: "download-two",
  },
];

export const links = [
  {
    title: "Cancer Research UK guidance on fentanyl",
    description:
      "Click this link to access an easy-to-digest summary of what fentanyl is, how it works and what side effects it can cause.",
    icon: <LinkIcon1 />,
    link: "https://www.cancerresearchuk.org/about-cancer/cancer-in-general/treatment/cancer-drugs/drugs/fentanyl",
    trigger: "link-3",
  },
  {
    title: "What is cancer pain? An ESMO guide for patients",
    description:
      "The European Society for Medical Oncology (ESMO) have made a guide to help you, as well as your carers, friends and family, better understand cancer pain and its treatment. Use this guide to find out about different types of treatment and what side effects you might get.",
    icon: <LinkIcon2 />,
    link: "https://www.esmo.org/content/download/211169/3738827/1/EN-Cancer-Pain-Management-Guide-for-Patients.pdf",
    trigger: "link-4",
  },
  {
    title: "Macmillan Cancer Support: Pain",
    description:
      "Browse these webpages to find out more about the types of cancer pain, how to talk about your pain and who can provide help if you need it.",
    icon: <LinkIcon3 />,
    link: "https://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/pain",
    trigger: "link-5",
  },
  {
    title: "Macmillan Cancer Support: Pain management without drugs",
    description:
      "This page will provide you with further information on non-drug treatments for cancer and how complementary therapies can be helpful when managing pain.",
    icon: <LinkIcon4 />,
    link: "https://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/pain/pain-management-without-drugs",
    trigger: "link-6",
  },
];
