import React, { useEffect, useRef, useState } from "react";
import useEventListener from "../../../hooks/useEventListener";

export default function SmallHeading(props) {
  const heading = useRef(null);
  const [taglineLeft, setTaglineLeft] = useState(0);
  const [taglineTop, setTaglineTop] = useState(0);

  const positionTaglineCircle = () => {
    const headingEl = heading.current;
    const taglineEl = headingEl.querySelector(".tagline");
    const pointerEl = headingEl.querySelector(".tagline-pointer");
    const taglinePos = taglineEl.getBoundingClientRect();

    setTaglineTop(taglinePos.top + 6);
    setTaglineLeft(taglinePos.left - 25);

    pointerEl.style.setProperty("--line-width", `${taglinePos.left}px`);
  };

  useEffect(() => {
    positionTaglineCircle();
  }, []);

  useEventListener("resize", positionTaglineCircle);

  return (
    <div ref={heading} className="heading--small">
      <div className="container">
        <div
          style={{
            top: taglineTop,
            left: taglineLeft,
          }}
          className="tagline-pointer tagline-pointer--resource"
        ></div>
        <div
          className="tagline"
          dangerouslySetInnerHTML={{ __html: props.tagline }}
        ></div>
        <h1 dangerouslySetInnerHTML={{ __html: props.title }}></h1>

        {props.copy && (
          <div
            className="heading-copy"
            dangerouslySetInnerHTML={{ __html: props.copy }}
          ></div>
        )}

        {props.copyComponent && (
          <div
            className="heading-copy"
          >
            {props.copyComponent()}
          </div>
        )}

      </div>
    </div>
  );
}
