import React from "react";

export default function SectionContraindications() {
  return (
    <section className="section section--contraindications section--white section--full-width">
      <div className="inner-container">
        <h2>
          Contraindications<sup>1</sup>
        </h2>


        <div className="grid grid-2-cols">
          <div>
            Hypersensitivity to fentanyl citrate, mannitol (E421), silicified microcrystalline cellulose, croscarmellose sodium or magnesium stearate
          </div>
          <div>
            Opioid-naïve patients
          </div>
          <div>
            Patients without maintenance opioid therapy as there is increased risk of respiratory depression
          </div>
          <div>
            Severe respiratory depression or severe obstructive lung conditions
          </div>
          <div>
            Treatment of acute pain other than breakthrough pain
          </div>
          <div>
            Patients being treated with medicinal products containing sodium oxybate
          </div>
        </div>

      </div>
    </section>
  );
}
