import React, { useEffect } from "react";
import { References, HomeHero, ExternalLink } from "../../../components";
import SectionPrescribe from "./section-prescribe";
import SectionAvailableDoses from "./section-available-doses";
import SectionContraindications from "./section-contraindications";
import SectionGraph from "./section-graph";
import SectionConsiderations from "./section-considerations";
import {Link, useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";

import Guitar from "../../../img/guitar@2x.png";
import FlowChartImg from "../../../img/flow-chart@2x.png"

import { HashLink as HashLink } from 'react-router-hash-link';
export default function Home() {
  useEffect(() => {
    document.title = "Fenhuma - HCP";
  }, []);

  let navigate = useNavigate();

  return (
    <main>
      <HomeHero />
      <SectionPrescribe />

      <section className="section section--full-width section--full-width--left section--white breakthrough-pain-section">
        <div className="inner-container inner-container--large breakthrough-pain">
          <h3>What is breakthrough pain?</h3>
          <p>
            <strong>
              Breakthrough pain is a transient exacerbation of otherwise
              controlled chronic background pain.<sup>1</sup>
            </strong>
          </p>
          <p>
            Breakthrough cancer pain is prevalent in cancer patients, with its
            prevalence ranging from 19–95% depending on study, and it
            significantly impacts a patient’s quality of life.<sup>5</sup>
          </p>
          <p>
            A typical episode of breakthrough pain will have the following
            characteristics:<sup>6</sup>
          </p>
          <ul>
            <li>Rapid onset, reaching maximum pain intensity within minutes</li>
            <li>Often severe pain</li>
            <li>An average duration of ~30 minutes</li>
          </ul>

          <p>
            Breakthrough pain can co-exist with adequately controlled background
            pain and should, therefore, be distinguished from ‘end of dose
            failure’, where background opioid therapy is not suitably
            controlling the background pain.<sup>7</sup>{" "}
          </p>

          <p>
            Breakthrough pain can place significant physical, psychological and
            economic burdens on patients, and they may experience increased
            levels of anxiety and depression.<sup>6</sup> The aim of breakthrough pain
            management is to reduce the intensity, severity and impact of each
            bout of pain.<sup>6</sup>{" "}
          </p>

          <p>
            When correctly managed, individualised breakthrough cancer
            pain therapy has been shown to improve patient quality of life.
            <sup>5</sup>
          </p>

          <em style={{"font-size": "14px", "font-weight" : 700}}>
            that's where fenhuma<sup>&reg;</sup> comes in
          </em>
        </div>
      </section>

      <section className="section section--half-half section-grey">
        <div className="inner-container inner-container--large">
          <div className="left">
              <img src={Guitar} width={460} alt="A man is practicing the guitar"/>
          </div>
          <div className="right">
            <h3 id="what-is-fenhuma">
              What is Fenhuma<sup>&reg;</sup>?
            </h3>
            <p>
              Fenhuma<sup>&reg;</sup> is used for the treatment of adult cancer
              patients already on opioid analgesic therapy for control of their persistent
              cancer pain but require additional treatment for their
              breakthrough pain.<sup>2</sup> <br/> Fenhuma<sup>&reg;</sup> is a quick
              dissolving sublingual tablet formulation.<sup>1</sup>
            </p>
            <p>
              Fenhuma<sup>&reg;</sup> tablets contain the active ingredient
              fentanyl (also known as fentanil) in the form of fentanyl citrate. Fentanyl is a phenylpiperidine derivative and is a potent µ-opioid analgesic with rapid onset of
              analgesia and short duration of action.<sup>1</sup> Fenhuma
              <sup>&reg;</sup> sublingual opioid tablets are indicated for breakthrough pain management
              in adult patients using opioid
              therapy for chronic cancer pain.<sup>1</sup>
            </p>


            <div style={{"marginTop" : "2rem"}}>
              <NavLink to="/hcp/initiating-fenhuma" className="btn btn--primary inline-block">Managing breakthrough pain</NavLink>
            </div>
          </div>
        </div>
      </section>

      <SectionAvailableDoses />

      <section className="section section--grey dose-titration">
        <div className="inner-container">
          <h3 className="text-center">
            Dose titration is required for all patients commencing treatment with Fenhuma<sup>®</sup>
          </h3>

          <p className="text-center">
            The object of dose titration is to identify an optimal maintenance dose for the ongoing treatment of breakthrough cancer pain episodes. <br/> The optimal dose should provide adequate breakthrough analgesia with an acceptable level of adverse reactions.<sup>1</sup>
          </p>

          <img src={FlowChartImg} alt=""/>

          <p aria-hidden="false" className="off-screen-only">
            Starting dose: 100 µg.
            Adequate pain relief achieved within 15–30 minutes?
            Yes.
            Use this dose for subsequent breakthrough pain episodes.
            No.
            Take a second tablet (see table to determine strength of next dose).
            Increase first tablet to next highest strength for next breakthrough pain episode.
          </p>

          <div className="text-center">
            <HashLink to="/hcp/initiating-fenhuma#titrate" className="btn btn--primary inline-block">How to titrate Fenhuma<sup>®</sup></HashLink>
          </div>

        </div>
      </section>

      <SectionContraindications />
      <SectionConsiderations />

      <section className="section section--full-width section--bottom-boxes section--white">
        <div className="inner-container">
          <div className="grid grid-2-cols">
            <div className="text-center cta">
              <h4>
                Side effects
              </h4>

              <p>
                Find out more about the side effects <span className="nowrap">associated with Fenhuma</span><sup>®</sup>
              </p>

              <div className="">
                <a target="_blank" className="btn btn--primary inline-block on-blue" href="https://kva-glenmark.s3.eu-west-2.amazonaws.com/fenhuma/patient/GLEN24392+-+Fenhuma+patient+website+download+Side+effects_Update_v0.3.pdf">
                  Side effects of Fenhuma<sup>®</sup>
                </a>
              </div>
            </div>
            <div className="text-center cta">
              <h4>
                Helpful links
              </h4>

              <p>
                A range of helpful links covering pain management and the use of fentanyl for breakthrough pain are available on our resources page
              </p>

              <div>
                <NavLink to="/hcp/resources" className="btn btn--primary inline-block on-blue">Resources</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>


      <References showDivider={true}>

        <ol>
          <li>Fenhuma Summary of Product Characteristics (SmPC). Available at: <ExternalLink link="https://www.medicines.org.uk/emc/search?q=fenhuma">https://www.medicines.org.uk/emc/search?q=fenhuma</ExternalLink>.</li>
          <li>MHRA Public Assessment Report. Fenhuma sublingual tablets (Glenmark Pharmaceuticals Ltd). Available at: <ExternalLink link="https://products.mhra.gov.uk/search/?search=fenhuma&page=1&doc=Par&rerouteType=0">https://products.mhra.gov.uk/search/?search=fenhuma&page=1&doc=Par&rerouteType=0</ExternalLink></li>
          <li>Data on file – Fenhuma sublingual tablets pricing vs rapid onset sublingual and buccal fentanyl products (Glenmark Pharmaceuticals Ltd). FENH/2022/07/001.</li>
          <li>Fenhuma Prescriber Guide. Available at: <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2515/Document">https://www.medicines.org.uk/emc/rmm/2515/Document</ExternalLink></li>
          <li>Rodríguez AT, et al. Support Care Cancer 2021;29:4799–4807.</li>
          <li>Zeppetella G. Guidelines in Practice. Breakthrough pain should be distinguished from background pain. March 2009. Available at: <ExternalLink link="https://www.guidelinesinpractice.co.uk/pain/breakthrough-pain-should-be-distinguished-from-background-pain/306787.article">https://www.guidelinesinpractice.co.uk/pain/breakthrough-pain-should-be-distinguished-from-background-pain/306787.article</ExternalLink></li>
          <li>Davies AN, et al. Eur J Pain 2009;13:331–338.</li>
        </ol>
        <p>
          <sup>&reg;</sup> ABSTRAL is a registered trademark of Kyowa Kirin Ltd.
        </p>
      </References>
    </main>
  );
}
