import React from "react";
import { Image } from "../../../components";
import RedBoxJPG from "../../../img/red-box.png";
import Box1 from "../../../img/Fenhuma-Packshot_100mg_1.png"
import Box2 from "../../../img/Fenhuma-Packshot_100mg_2.png"
import Box3 from "../../../img/Fenhuma-Packshot_100mg_3.png"
import Box4 from "../../../img/Fenhuma-Packshot_100mg_4.png"
import Box5 from "../../../img/Fenhuma-Packshot_100mg_5.png"
import Box6 from "../../../img/Fenhuma-Packshot_100mg_6.png"

export default function SectionAvailableDoses() {
  return (
    <section className="section section--white section--half-half section-available-doses">
      <div className="inner-container">
        <div className="left">
          <h3 id="why-choose-fenhuma">Why choose Fenhuma<sup>®</sup>?</h3>
          <p>
            Fenhuma<sup>®</sup> is bioequivalent to Abstral<sup>®</sup> and is 25% cheaper.<sup>2,3</sup> Switching prescribing to Fenhuma<sup>®</sup> from other rapid onset fentanyl products could result in immediate cost improvements.<sup>3</sup>
          </p>
          <p style={{"marginBottom": "0rem"}}>
            <strong>
              Price comparison of rapid onset sublingual and buccal fentanyl tablets available in the UK<sup>1</sup>
            </strong>
          </p>
          <p >
            Pricing comparison based on 800 µg dose and largest available pack size. This pricing comparison does not represent therapeutic effect or bioequivalence between products. Effentora<sup>®</sup> is a registered trademark of Teva Pharma UK. Abstral<sup>®</sup> is a registered trademark of Kyowa Kirin Ltd.
          </p>

          <table className="table">
            <thead>
              <tr>
                <th className="strong">Brand</th>
                <th>Method of administration</th>
                <th>Price per tablet</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="strong">Effentora<sup>®</sup></td>
                <td>Buccal tablet</td>
                <td className="strong">£4.99</td>
              </tr>
              <tr>
                <td className="strong">Abstral<sup>®</sup></td>
                <td rowSpan={2}>Sublingual tablet</td>
                <td className="strong">£4.99</td>
              </tr>
              <tr>
                <td className="strong blue">Fenhuma<sup>®</sup></td>
                <td className="strong blue">£3.74</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="right">
          <div className="white-box text-center">
            <h3 style={{"font-size": "18px"}}>Available doses of Fenhuma<sup>®</sup></h3>
            <p>
              Fenhuma<sup>®</sup> is available in multiple strengths so you can determine the most appropriate dose for your patients.<sup>1</sup>
            </p>

            <div className="grid grid-3-cols doses-grid">
              <div>
                <img className="img-blocked" src={Box1}  alt=""/>
                100 µg
              </div>
              <div>
                <img className="img-blocked" src={Box2}  alt=""/>
                200 µg
              </div>
              <div>
                <img className="img-blocked" src={Box3} alt=""/>
                300 µg
              </div>
              <div>
                <img className="img-blocked" src={Box4}  alt=""/>
                400 µg
              </div>
              <div>
                <img className="img-blocked" src={Box5} alt=""/>
                600 µg
              </div>
              <div>
                <img className="img-blocked" src={Box6}  alt=""/>
                800 µg
              </div>
            </div>

            <p>
              Fenhuma<sup>®</sup> facilitates the rapid absorption of fentanyl within minutes, ensuring rapid malignant pain relief from breakthrough  pain episodes for your adult cancer patients.<sup>1</sup>
            </p>

          </div>

        </div>
      </div>
    </section>
  );
}
