import React, { useEffect, useRef } from "react";
import ClockIcon from "../../../img/clock-icon.svg";
import CheaperIcon from "../../../img/cheaper-icon.svg";
import StarIcon from "../../../img/star-icon.svg";
import HomeHeader from "../../../img/home-header.png";
import ExternalLink from "../../external-link/external-link";

export default function HomeHero() {
  const heroRef = useRef();

  useEffect(() => {
    positionHeroImages();

    window.addEventListener("resize", () => {
      positionHeroImages();
    });
  }, []);

  const positionHeroImages = () => {
    const heroElm = heroRef.current;
    const windowWidth = window.innerWidth;

    if (heroElm) {
      const images = document.querySelectorAll(".image-wrapper img");
      const heroHeight = heroElm.querySelector(".left").offsetHeight;
      const rightElm = heroElm.querySelector(".right");
      const rightPosition = rightElm.getBoundingClientRect();

      if (windowWidth <= 600) {
        images.forEach((img) => {
          img.style.height = `314px`;
          img.style.width = `${(windowWidth - rightPosition.x)}px`;
          img.style.marginTop = `0px`;
        });
      } else {
        images.forEach((img) => {
          img.style.width = `${(windowWidth - rightPosition.x) - 30}px`;
          img.style.marginTop = `50px`;
          img.style.height = `${heroHeight + 35}px`;
        });
      }
    }
  };

  return (
    <div className="hero" ref={heroRef}>
      <div className="inner-container">

        <div className="left">
          <span className="welcome">Welcome</span>
          <h1>
            Breakthrough pain relief{" "}
            <small>
              for your adult cancer
              <br /> patients within minutes
            </small>
          </h1>


          <p>
            Fenhuma<sup>®</sup> (fentanyl) sublingual tablets are indicated for the management of breakthrough pain in adult patients using opioid therapy for chronic cancer pain. Breakthrough pain is a transient exacerbation of otherwise controlled chronic background pain.<sup>1</sup>
          </p>

          <p>
            The Fenhuma<sup>®</sup> <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2515/Document" title="Prescriber Guide"></ExternalLink> provides further guidance on the initiation, administration and titration of Fenhuma<sup>®</sup>. Use this booklet in conjunction with the <ExternalLink
             link="https://www.medicines.org.uk/emc/search?q=fenhuma"
             title="Summary of Product Characteristics"

            />.
          </p>

          <p>
            Patients and carers should be referred to the Fenhuma<sup>®</sup> <ExternalLink className="nowrap" title="Patient Information Leaflet" link="https://www.medicines.org.uk/emc/files/pil.13972.pdf"/> and be provided with a copy of the Fenhuma<sup>®</sup>
            <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2514/Document" title=" Patient & Carer Guide" className="nowrap"/>.
          </p>
        </div>
        <div className="right">
          <div className="image-wrapper">
            <img src={HomeHeader} alt="A man is outside enjoying the fresh air. Two friends are enjoying spending time together. A couple are embracing and feeling peaceful. A
man is practicing the guitar" />
          </div>
        </div>
      </div>
      <div className="inner-container inner-container--large">
        <div className="hero-boxes">
          <div>
            <div className="icon">
              <img src={StarIcon} alt="" />
            </div>
            <p>Fenhuma<sup>®</sup> is bioequivalent to Abstral<sup>®</sup> and is 25% cheaper
              <sup>2,3</sup></p>
          </div>
          <div>
            <div className="icon">
              <img src={CheaperIcon} alt="" />
            </div>
            <p>Initiating your adult cancer patients onto Fenhuma<sup>®</sup> may deliver
              immediate cost improvements compared with other rapid onset
              fentanyl products<sup>3</sup></p>
          </div>
          <div>
            <div className="icon">
              <img src={ClockIcon} alt="" />
            </div>
            <p>Rapid absorption of fentanyl occurs over ~30 minutes following
              administration of Fenhuma<sup>®1</sup></p>
          </div>
        </div>
      </div>
    </div>
  );
}
