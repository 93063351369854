import React from "react";
import {
  PatientLargeHeading as LargeHeading,
  References,
  ExternalLink,
} from "../../../components";
import WhyHeader from "../../../img/patient-why-have-i-hero.png";
import RapidOnsetIcon from "../../../img/rapid-onset.svg";
import IntensePainIcon from "../../../img/intense-pain.svg";
import UsuallyLasts from "../../../img/usually-lasts.svg";
import NotControlledIcon from "../../../img/not-controlled.svg";
import PredictableIcon from "../../../img/predictable.svg";
import {NavLink} from "react-router-dom";


export default function Why() {
  return (
    <main>

      <LargeHeading
        image={WhyHeader}
        tagline=""
        alt="A woman is closing her eyes and looking peaceful"
        title="Why have I been<br />prescribed Fenhuma<sup>&reg;</sup>?"
        body="<p>Many people who are suffering from cancer will experience pain.<sup>1</sup></p>
      <p style='margin-top:1rem; margin-bottom: 1rem;'>Persistent pain is pain that continues over a long period of time and is often called ‘background pain’. It is
        usually controlled by long-term pain-relieving treatment(s), such as opioids.<sup>1</sup></p>
      <p><strong>Breakthrough</strong> pain, for which Fenhuma<sup>®</sup> is a treatment option, is different from <strong>background</strong>
        pain.<sup>1,2</sup></p>"
      />
      
      <section className="section section--grey section--half-half pointer pointer--top pointer--right-50 what-is-breakthrough-pain pointer--pull-up">
        <div className="container">
          <div className="left">
            <h2>
              What is breakthrough pain?
            </h2>
            <p>
              Breakthrough pain is short bursts of severe pain that ‘break through’ the background pain. This is known as breakthrough cancer pain.<sup>1</sup>
            </p>

            <p style={{"marginBottom": "-15px"}}>
              <strong>Treating breakthrough cancer pain episodes with Fenhuma<strong>®</strong></strong>
            </p>

            <p>Breakthrough pain is often treated with a different medication than that used to treat your background
              pain. While your background pain medication will take a while to start working, breakthrough pain
              relief medications, such as Fenhuma<sup>®</sup>, will act much more quickly.<sup>1,2</sup></p>
            <p>Fenhuma<sup>®</sup> dissolves quickly under the tongue and can give pain relief as soon as 10 minutes after taking
              it. It has been shown to give pain relief for more than 60 minutes, covering the full length of the
              average breakthrough pain episode.<sup>1,2</sup></p>
            <p>
              Fenhuma<sup>®</sup> should only be used for treating your breakthrough cancer pain. Do not use Fenhuma<sup>®</sup> for other short-term pain.<sup>2</sup>
            </p>


              <NavLink className="btn btn--patient" to="/patient/taking-fenhuma">Taking Fenhuma<sup>&reg;</sup></NavLink>

          </div>
          <div className="right">
            <div className="box">
              <p className="full-opacity">
                <strong>
                  Breakthrough pain happens in episodes, with each episode sharing the following characteristics:<sup>1</sup>
                </strong>
              </p>

              <ul>
                <li>
                  <img src={RapidOnsetIcon} alt=""/>
                  <span>Rapid onset (starts quickly)</span>
                </li>
                <li>
                  <img src={IntensePainIcon} alt=""/>
                  <span>Often very intense pain</span>
                </li>
                <li>
                  <img src={UsuallyLasts} alt=""/>
                  <span>Usually lasts around 30 minutes</span>
                </li>
                <li>
                  <img src={NotControlledIcon} alt=""/>
                  <span>Not controlled by background pain medication</span>
                </li>
                <li>
                  <img src={PredictableIcon} alt=""/>
                  <span>Can be predictable (e.g., triggered by walking or movement) or unpredictable (occurs at random)</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      <section className="section section--white section--full-width learn-more-about-pain">
        <div className="container">
          <h2>Want to learn more about cancer pain?</h2>
          <p>
            Discover more about breakthrough cancer pain and how to manage it using our range of resources
          </p>
          <NavLink className="btn btn--patient" to="/patient/useful-resources">
            Explore resources
          </NavLink>
        </div>
      </section>


      <References>
        <ol>
          <li>
            Fenhuma Patient & Carer Guide: Important risk minimisation information for patients and their carers. Available at <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2514/Document">https://www.medicines.org.uk/emc/rmm/2514/Document</ExternalLink>

          </li>
          <li>
            Fenhuma Summary of Product Characteristics (SmPC). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/search?q=fenhuma"
              title="https://www.medicines.org.uk/emc/search?q=fenhuma"
            />
          </li>

        </ol>
      </References>
    </main>
  );
}
