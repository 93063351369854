import DownloadIcon1 from "../../../img/download-icon-1.svg";
import LinkIcon1 from "../../../img/link-icon-1.svg";
import LinkIcon2 from "../../../img/link-icon-2.svg";
import LinkIcon3 from "../../../img/link-icon-3.svg";
import LinkIcon4 from "../../../img/link-icon-4.svg";
import LinkIcon5 from "../../../img/link-icon-5.svg";
import LinkIcon8 from "../../../img/link-icon-8.svg";
import RiskIcon1 from "../../../img/risk-icon-1.svg";
import RiskIcon2 from "../../../img/risk-icon-2.svg";
import { ExternalLink } from "../../../components";

function Risk1(props) {
  return (
    <span>
      The Fenhuma<sup>®</sup> Prescriber Guide is designed to support HCPs in
      the diagnosis of breakthrough pain in patients suffering from cancer. It
      provides guidance on the initiation, administration and titration of
      Fenhuma<sup>®</sup> and promotes engagement with patients and their
      carers. This booklet should be used in conjunction with the{" "}
      <ExternalLink
        title="Summary of Product Characteristics."
        link="https://www.medicines.org.uk/emc/search?q=fenhuma"
      />{" "}
    </span>
  );
}

function Risk2(props) {
  return (
    <span>
      The Fenhuma<sup>®</sup> Patient & Carer Guide provides an overview of
      breakthrough cancer pain, alongside guidance on what Fenhuma<sup>®</sup>{" "}
      is, what starting treatment will involve and information on adverse
      events. This, along with the{" "}
      <ExternalLink
        title="Patient Information Leaflet"
        link="https://www.medicines.org.uk/emc/files/pil.13972.pdf"
      ></ExternalLink>
      , should be shared with patients and carers.
    </span>
  );
}

export const risk = [
  {
    title: "Fenhuma<sup>®</sup> Prescriber Guide",
    descriptionComp: Risk1(),
    icon: <RiskIcon1 />,
    link: "https://www.medicines.org.uk/emc/rmm/2515/Document",
    trigger: "risk-one",
    renderType: "component",
  },
  {
    title: "Fenhuma<sup>®</sup> Patient & Carer Guide",
    descriptionComp: Risk2(),
    icon: <RiskIcon2 />,
    link: "https://www.medicines.org.uk/emc/rmm/2514/Document",
    trigger: "risk-two",
    renderType: "component",
  },
];

export const downloads = [
  {
    title: "Side effect summary",
    description:
      "Fenhuma<sup>®</sup> and other opioid therapies can lead to a range of side effects. Share this easy-to-digest summary of the side effects associated with Fenhuma<sup>®</sup> with your patients who have been prescribed Fenhuma<sup>®</sup>.",
    icon: <DownloadIcon1 />,
    link: "https://kva-glenmark.s3.eu-west-2.amazonaws.com/fenhuma/patient/GLEN24392+-+Fenhuma+patient+website+download+Side+effects_Update_v0.3.pdf",
    trigger: "download-one",
  },
];

export const links = [
  {
    title: "Checklist for prescribers",
    description:
      "Use this handy checklist, provided by the Faculty of Pain Medicine, when considering the use of Fenhuma<sup>®</sup> in your adult cancer patients.",
    icon: <LinkIcon1 />,
    link: "http://www.fpm.ac.uk/opioids-aware-structured-approach-opioid-prescribing/checklist-prescribers",
    trigger: "link-1",
  },
  {
    title: "A structured approach to opioid prescribing",
    description:
      "The Faculty of Pain Medicine provide a range of resources to help you manage prescription of opioids in your patients. Utilise these webpages to guide your opioid-prescribing practices.",
    icon: <LinkIcon2 />,
    link: "http://www.fpm.ac.uk/opioids-aware/structured-approach-opioid-prescribing",
    trigger: "link-2",
  },
  {
    title: "WHO analgesic ladder",
    description:
      "The WHO guidelines for management of pain in adult and adolescent cancer patients",
    icon: <LinkIcon8 />,
    link: "https://www.who.int/publications/i/item/9789241550390",
    trigger: "link-6",
  },
  {
    title: "Cancer Research UK guidance on fentanyl ",
    description:
      "Direct your patients who are prescribed Fenhuma<sup>®</sup> to this page to provide an easy-to-digest summary of what fentanyl is, how it works and what side effects it can cause.",
    icon: <LinkIcon3 />,
    link: "https://www.cancerresearchuk.org/about-cancer/cancer-in-general/treatment/cancer-drugs/drugs/fentanyl",
    trigger: "link-3",
  },
  {
    title: "What is cancer pain?",
    description:
      "This Macmillan page provides an insight into the different types of cancer pain, along with who patients can talk to about their pain and different types of treatment they may be offered to alleviate it.",
    icon: <LinkIcon4 />,
    link: "https://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/pain",
    trigger: "link-4",
  },
  {
    title: "Managing cancer pain without drugs",
    description:
      "Share this link with your patients to provide them with further information from Macmillan on non-drug treatments for cancer and how complementary therapies can be helpful when managing pain.",
    icon: <LinkIcon5 />,
    link: "https://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/pain/pain-management-without-drugs",
    trigger: "link-5",
  },
];
