import React, {useEffect, useRef} from "react";
import { Image, ExternalLink } from "../../";
import CirclePNG from "../../../img/hero-circles.png";
import CircleWEBP from "../../../img/hero-circles.webp";
import HeroJPG from "../../../img/initiating-hero.jpg";
import HeroWEBP from "../../../img/initiating-hero.webp";
import HomeHeader from "../../../img/home-header.png";

export default function InitiatingHero() {

  const heroRef = useRef();
  useEffect(() => {
    positionHeroImages();

    window.addEventListener("resize", () => {
      positionHeroImages();
    });
  }, []);

  const positionHeroImages = () => {
    const heroElm = heroRef.current;
    const windowWidth = window.innerWidth;

    if (heroElm) {
      const images = document.querySelectorAll(".image-wrapper img");
      const heroHeight = heroElm.querySelector(".left").offsetHeight;
      const rightElm = heroElm.querySelector(".right");
      const rightPosition = rightElm.getBoundingClientRect();

      if (windowWidth <= 600) {
        images.forEach((img) => {
          img.style.height = `314px`;
          img.style.width = `${(windowWidth - rightPosition.x)}px`;
          img.style.marginTop = `0px`;
        });
      } else {
        images.forEach((img) => {
          img.style.width = `${(windowWidth - rightPosition.x) - 30}px`;
          img.style.marginTop = `50px`;
          img.style.height = `${heroHeight + 35}px`;
        });
      }
    }
  };

  return (
    <div className="hero hero--initiating" ref={heroRef}>
      <div className="inner-container">

        <div className="left">
          <h1>
            Guidance for initiating your patients on Fenhuma<sup>&reg;</sup>{" "}
            (fentanyl)
          </h1>
          
          <p>
            This page contains important information to consider when initiating your patients on Fenhuma<sup>®</sup>.
          </p>

          <p>


            The Fenhuma<sup>®</sup> <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2515/Document" title={["Prescriber Guide"]}/> provides support on the diagnosis of breakthrough pain in cancer patients, alongside further guidance on the initiation, administration and titration of Fenhuma<sup>®</sup>. Consult this in conjunction with the full <ExternalLink link="https://www.medicines.org.uk/emc/search?q=fenhuma">Summary
            of Product Characteristics</ExternalLink> before prescribing Fenhuma<sup>®</sup>.
          </p>
          
          <p>
            Fenhuma<sup>®</sup> is indicated for the management of breakthrough pain in adult patients using opioid therapy for chronic cancer pain. Breakthrough pain is a transient exacerbation of otherwise controlled chronic background pain.
          </p>

        </div>
        <div className="right">
          <div className="image-wrapper">
            <img src={HomeHeader} alt="A man is outside enjoying the fresh air. Two friends are enjoying spending time together. A couple are embracing and feeling peaceful. A
man is practicing the guitar" />
          </div>
        </div>
      </div>
    </div>
  );
}
