import React, { useContext } from "react";
import { AppContext } from "../../App";
import { useSite } from "../../hooks";

export default function ExternalPopup() {
  const { externalLinkURL, setShowExternalLinkPopup } = useContext(AppContext);
  const { hostname } = new URL(externalLinkURL);
  const { currentSite } = useSite();

  const goToURL = () => {
    window.open(externalLinkURL, "_blank").focus();
    setShowExternalLinkPopup(false);
    //window.location = externalLinkURL;
  };

  return (
    <div className="external-popup-outer">
      <div className="external-popup-inner">
        <h2>You are now leaving fenhuma.co.uk</h2>
        <p>
          This link will take you to {hostname}
          <br />
          Glenmark takes no responsibility for the content of external websites.
        </p>
        <div className="button-group">
          <button className="btn btn--outline" onClick={() => setShowExternalLinkPopup(false)}>
            Cancel
          </button>
          <button
            className={currentSite === "patient" ? "btn btn--patient" : "btn btn--primary"}
            onClick={() => goToURL()}
          >
            Continue
          </button>
        </div>
        <p>NP-UK-FENH-0012 Date of preparation: October 2024</p>
      </div>
    </div>
  );
}
