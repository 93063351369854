import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { accept } from "../../reducers/hcpSlice";
import { useSite } from "../../hooks";
import { Footer, ExternalLink } from "../";
import logo from "../../img/fenhuma-logo.png";

export default function HCPPopup() {
  const dispatch = useDispatch();
  const { currentSite } = useSite();
  const location = useLocation();
  let navigate = useNavigate();

  const close = (navigateTo) => {
    if (currentSite === "root") {
      navigate(navigateTo, { replace: true });
      dispatch(accept());
    } else {
      dispatch(accept());

      if (location.pathname.startsWith(`/${navigateTo}`)) {
        navigate(location.pathname+location.hash, { replace: true });
      } else {
        navigate(navigateTo, { replace: true });
      }
    }

    document.body.classList.remove(`site-hcp`);
    document.body.classList.remove(`site-patient`);
    document.body.classList.remove(`site-root`);
    document.body.classList.add(`site-${navigateTo}`);
  };

  return (
    <>
      <div className="hcp-popup-header">
        <div className="logo">
          <img src={logo} alt="Fenhuma&reg;" />
        </div>

        <div className="inner-container">
          <h1>
            Welcome to
            <small>
              Glenmark’s Fenhuma<sup>&reg;</sup> (fentanyl) UK product website
            </small>
          </h1>
          <div className="button-container">
            <button
              className="btn btn--outline enter-patient"
              onClick={() => close("patient")}
            >
              I am a UK patient who has been prescribed Fenhuma<sup>&reg;</sup>
            </button>
            <button
              className="btn btn--outline enter-hcp"
              onClick={() => close("hcp")}
            >
              I am a UK healthcare professional
            </button>
          </div>
          <p className="disclaimer">
            By clicking on this link, you are confirming you are a UK Healthcare
            Professional.
          </p>
          <div className="link-container">
            <p className="disclaimer">
              If neither of the two options apply to you, but you would like
              more information, please refer to the following:
            </p>
            <ul>
              <li>
                <ExternalLink
                  link="https://www.medicines.org.uk/emc/search?q=fenhuma"
                  title="Summary of Product Characteristics"
                  className="welcome-spc"
                />
              </li>
              <li>
                <ExternalLink
                  link="https://www.medicines.org.uk/emc/files/pil.13972.pdf"
                  title="Patient Information Leaflet"
                  className="welcome-pil"
                />
              </li>
              <li>
                <ExternalLink
                  link="https://mhraproducts4853.blob.core.windows.net/docs/bfebb97b9ad07f85696d34c1d96d96bbefe15758"
                  title="Public Assessment Report"
                  className="welcome-par"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section className="section section--grey section--full-width hcp-popup">
        <div className="inner-container">
          <h2>Reporting side effects</h2>
          <p>
            If you get any side effects, talk to your doctor, pharmacist or
            nurse. This includes any possible side effects not listed in the
            package leaflet. You can also report side effects directly via the
            Yellow Card Scheme at{" "}
            <ExternalLink
              link="https://yellowcard.mhra.gov.uk/"
              title="https://yellowcard.mhra.gov.uk/"
            />
            . By reporting side effects, you can help provide more information
            on the safety of this medicine.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}
