import React from "react";
import {
  PatientLargeHeading as LargeHeading,
  CTA,
  References,
  ExternalLink,
} from "../../../components";
import { NavLink } from "react-router-dom";
import HomeHeader from "../../../img/fenhuma-hero@2x.png";
import StartingTreatmentLeft from "../../../img/starting-treatment-left@2x.png";
import TalkToYourDoctor from "../../../img/patient-talk-to-your-doctor@2x.png";
import PatientPhoneIcon from "../../../img/patient-phone-icon.svg";
import DownloadIcon from "../../../img/download-icon-white.svg";
import DownArrow from "../../../components/icons/down-arrow";

export default function PatientHome() {
  return (
    <main>
      <LargeHeading
        image={HomeHeader}
        alt=""
        tagline="Welcome"
        title="Introducing Fenhuma<sup>&reg;</sup>"
        body="Fenhuma<sup>®</sup> (active drug name fentanyl) is a treatment for adults who are already taking strong pain-relieving medicine (opioids) for their cancer pain but need treatment for breakthrough cancer pain. Breakthrough pain is pain that happens suddenly, even though you have taken or used your usual opioid pain-relieving medicine.<sup>1,2</sup>"
        classes="home-heading"
      />
      <section className="section section--grey direction">
        <div className="container">
          <div className="direction-box">
            <div className="top"></div>
            <div className="content">
              <h3>
                Before you take Fenhuma<sup>&reg;</sup>
              </h3>
              <p>
                Before taking Fenhuma<sup>&reg;</sup> for the first time, your doctor will
                explain how Fenhuma<sup>&reg;</sup> should be taken to effectively treat
                your breakthrough pain.
              </p>

              <p>
                <DownArrow />
              </p>
            </div>
            <div className="bottom"></div>
            <a href="#before">&nbsp;</a>
          </div>
          <div className="direction-box">
            <div className="top"></div>
            <div className="content">
              <h3>
                Side effects of Fenhuma<sup>&reg;</sup>
              </h3>
              <p>
                You may get some side effects when taking Fenhuma<sup>&reg;</sup>.
              </p>

              <p>
                <DownArrow />
              </p>
            </div>
            <div className="bottom"></div>
            <a href="#side-effects">&nbsp;</a>
          </div>
          <div className="direction-box">
            <div className="top"></div>
            <div className="content">
              <h3>Speaking to your doctor</h3>
              <p>
                It is important that you keep your healthcare team up to date with how
                your Fenhuma<sup>&reg;</sup> treatment is going.
              </p>

              <p>
                <DownArrow />
              </p>
            </div>
            <div className="bottom"></div>
            <a href="#doctor">&nbsp;</a>
          </div>
        </div>
      </section>

      <section
        id="before"
        className="section section--white section--half-half pointer pointer--top pointer--right-50"
      >
        <div className="container mobile-reverse">
          <div className="left">
            <img
              className=""
              src={StartingTreatmentLeft}
              alt="A man is closing his eyes and contemplating. A woman is looking into the distance"
            />
          </div>
          <div className="right">
            <h2>
              What do I need to know before starting treatment with Fenhuma<sup>®</sup>?
            </h2>

            <p>
              Please read the{" "}
              <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2514/Document">
                Patient & Carer Guide
              </ExternalLink>
              <sup>2</sup> before you start taking Fenhuma® to ensure it is the right
              treatment choice for you.
            </p>

            <p>
              It is important to read the product package leaflet provided with your
              medicine. This information is not a substitute for the product package
              leaflet. The package leaflet contains additional important information on
              the use of the medicine.
            </p>

            <p>
              Before starting treatment and regularly during treatment, your doctor will discuss with you what you may expect from using Fenhuma<sup>&reg;</sup>, when and how long you need to take it, when to contact your doctor, and when you need to stop it.
            </p>

            <p>
              Fenhuma<sup>®</sup> contains fentanyl (also spelt fentanil), which is an
              opioid. Opioids can cause addiction, and this means you can get withdrawal
              symptoms if you stop taking it suddenly. Fenhuma<sup>®</sup> provides a high
              dose of opioid that can be fatal if not used appropriately.
            </p>

            <p>
              You should not use Fenhuma<sup>®</sup> if:<sup>1</sup>
            </p>

            <ul>
              <li>
                You are allergic to fentanyl or any other ingredient in this medicine
              </li>
              <li>You have severe breathing problems</li>

              <li>
                You are not regularly using a prescribed opioid medicine (e.g codeine,
                fentanyl, hydromorphone, morphine, oxycodone, pethidine), every day on a
                regular schedule, for at least a week, to control your persistent pain. If
                you have not been using these medicines you must not use Fenhuma because
                it may increase the risk that breathing could become dangerously slow
                and/or shallow, or even stop
              </li>

              <li>You suffer from short-term pain other than breakthrough pain</li>
              <li>You are being treated with medicines containing sodium oxybate</li>
            </ul>

            <p>
              Fenhuma<sup>®</sup> should not be used unless you have previously been
              prescribed other opioids.<sup>1</sup>
            </p>

            <p className="bold">
              Fenhuma<sup>®</sup> is prescribed for the treatment of breakthrough cancer
              pain.
            </p>

            <NavLink
              to="/patient/why-have-i-been-prescribed-fenhuma"
              className="btn btn--patient"
              style={{ marginTop: "2rem" }}
            >
              Why have I been prescribed Fenhuma<sup>&reg;</sup>?
            </NavLink>
          </div>
        </div>
      </section>

      <CTA
        heading="Starting Fenhuma<sup>®</sup> and finding the right dose for you"
        copy="<p>Fenhuma<sup>®</sup> is available in different strengths. Your doctor will help find the right dose for you and will make sure you are supported throughout your treatment, to help lower the risk of side effects.<sup>1</sup></p>"
        link="/patient/taking-fenhuma"
        linkCopy="Starting Fenhuma<sup>&reg;</sup>"
        className=""
      />
      <section
        id="side-effects"
        className="section section--white section--half-half speak-to-doctor pointer pointer--left-50"
      >
        <div className="container">
          <div className="left">
            <div className="beige-backdrop text-center">
              <img src={PatientPhoneIcon} alt="" />
              <p>
                If you start to feel <strong>unusually or extremely sleepy</strong> or
                your <strong>breathing becomes slow or shallow</strong>, you or your carer
                should immediately contact your doctor or local hospital for emergency
                help.<sup>1</sup>
              </p>
            </div>
          </div>
          <div className="right">
            <h2>
              Reported side effects<sup>1</sup>
            </h2>
            <p>
              Like all medicines, Fenhuma<sup>®</sup> can cause side effects, although not
              everybody gets them. As with other opioid medicines, Fenhuma<sup>®</sup>{" "}
              does carry a risk of accidental overdose and abuse, so it is important you
              discuss with your doctor how to reduce these risks.
            </p>

            <NavLink
              to="/patient/side-effects"
              className="btn btn--patient"
              style={{ marginTop: "2rem" }}
            >
              Potential side effects
            </NavLink>
          </div>
        </div>
      </section>

      <section id="doctor" className="section section--grey">
        <div className="container">
          <h2>
            Speaking to your doctor<sup>1</sup>
          </h2>

          <p className="more-space">
            Fenhuma<sup>®</sup> is a strong opioid analgesic and should only be used by
            you, according to your doctor’s instructions. It should not be used by anyone
            else as it could present a serious risk to their health, especially for
            children.
          </p>
          <p>
            When taking Fenhuma<sup>®</sup>, inform your doctor or dentist that you are
            taking this medicine if:
          </p>
          <ul>
            <li>You are due to have surgery</li>
            <li>
              You get pain or increased sensitivity to pain (hyperalgesia) that does not
              get better with the higher dose of your breakthrough pain medicine that your
              doctor has told you to take
            </li>
            <li>
              You get a combination of the following side effects: nausea, vomiting,
              anorexia, fatigue, weakness, dizziness and low blood pressure
            </li>
          </ul>

          <p>Your doctor may need to check you more closely if:</p>

          <ul>
            <li>
              You or anyone in your family have ever abused or been dependent on alcohol,
              prescription medicines or illegal drugs
            </li>
            <li>You smoke</li>
            <li>
              You have had problems with your mood or have been previously treated by a
              psychiatrist
            </li>
          </ul>

          <p>
            It is important that you give your doctor all the information they may need
            and keep them informed of any changes in your treatment.
          </p>
          <p>
            If you experience any problems during your treatment with Fenhuma<sup>®</sup>,
            you should consult your healthcare professional immediately. This includes any
            side effects not listed within your Patient Information Leaflet. You can also
            report side effects directly via the Yellow Card Scheme Website:{" "}
            <ExternalLink link="https://yellowcard.mhra.gov.uk">
              https://yellowcard.mhra.gov.uk
            </ExternalLink>{" "}
            or search for MHRA Yellow Card in Google Play or Apple App Store.
          </p>
          <p className="more-space">
            By reporting side effects, you can help provide more information on the safety
            of this medicine.
          </p>

          <a
            target="_blank"
            href="https://kva-glenmark.s3.eu-west-2.amazonaws.com/fenhuma/patient/GLEN24392+-+Fenhuma+patient+website+download+Side+effects_Update_v0.3.pdf"
            className="btn btn--patient"
          >
            Common side effects <img src={DownloadIcon} alt="" />
          </a>
        </div>
      </section>

      <section className="section section--white section--half-half pointer pointer--top pointer--right-50 talk-to-doctor">
        <div className="container">
          <div className="left">
            <img
              src={TalkToYourDoctor}
              alt="An elderly man is looking into the distance. A woman is sitting on a bench looking up to the sky"
            />
          </div>
          <div className="right">
            <h2>
              Talk to your doctor or pharmacist before taking Fenhuma<sup>®</sup> if you
              have or have recently had:<sup>1</sup>
            </h2>

            <ul>
              <li>
                A head injury (as Fenhuma<sup>®</sup> may cover up the extent of the
                injury)
              </li>
              <li>
                Breathing problems or suffer from myasthenia gravis (a condition
                characterised by muscle weakness)
              </li>
              <li>Problems with your heart, especially a slow heart rate</li>
              <li>Low blood pressure</li>
              <li>Liver or kidney disease</li>
              <li>
                A brain tumour and/or raised intracranial pressure (an increase of
                pressure in the brain which causes severe headache, nausea/vomiting and
                blurred vision)
              </li>
              <li>Mouth wounds or mucositis (swelling and redness within the mouth)</li>
              <li>Treatment with antidepressants or antipsychotics</li>
              <li>
                Developed adrenal insufficiency or lack of sex hormones (androgen
                deficiency) with opioid use
              </li>
              <li>
                Are or have ever been addicted to opioids, alcohol, prescription medicines
                or illegal drugs
              </li>
              <li>
                Previously suffered from withdrawal symptoms such as agitation, anxiety,
                shaking or sweating, when you have stopped taking alcohol or drugs
              </li>
              <li>
                Feel you need to take more of Fenhuma<sup>®</sup> to get the same level of
                pain relief
              </li>
            </ul>

            <p>
              It is important that you speak to your doctor if you have any of the above,
              as they may need to change your dose or switch you to a different
              painkiller.<sup>1</sup>
            </p>
          </div>
        </div>
      </section>

      <CTA
        heading="Discover more about fentanyl as an opioid for breakthrough cancer pain"
        copy="<p>Find helpful links and downloadable PDFs on our resources page.</p>"
        link="/patient/useful-resources"
        linkCopy="Explore resources"
        className=""
        id="side-effects"
      />

      <References className="pointer pointer--full">
        <ol>
          <li>
            Fenhuma Patient Information Leaflet (PIL). Available at:{" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/files/pil.13972.pdf"
              title="https://www.medicines.org.uk/emc/files/pil.13972.pdf"
            />
          </li>
          <li>
            Fenhuma Patient & Carer Guide: Important risk minimisation information for
            patients and their carers. Available at{" "}
            <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2514/Document">
              https://www.medicines.org.uk/emc/rmm/2514/Document
            </ExternalLink>
          </li>
        </ol>
      </References>
    </main>
  );
}
