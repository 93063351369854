import React, { useContext } from "react";
import { AppContext } from "../../App";

export default function ExternalLink(props) {
  const { setExternalLinkURL, setShowExternalLinkPopup } = useContext(AppContext);

  const domainExcludeList = ["glenmarkpharma.com", "glenmarkpharma.co.uk"];

  const processClickedLink = (el, link) => {
    const { hostname } = new URL(link);

    if (!domainExcludeList.includes(hostname)) {
      el.preventDefault();
      setExternalLinkURL(link);
      setShowExternalLinkPopup(true);
    }
  };

  if (props.children) {
    return (
      <a
        href={props.link}
        onClick={(e) => processClickedLink(e, props.link)}
        className={`external-link ${props.className ? props.className : ""}`}
      >
        {props.children}
      </a>
    );
  }

  return (
    <a
      href={props.link}
      onClick={(e) => processClickedLink(e, props.link)}
      className={`external-link ${props.className ? props.className : ""}`}
    >
      {props.title}
    </a>
  );
}
