import React from "react";
import { useSite } from "../../hooks";
import { NavLink } from "react-router-dom";

export default function CTA(props) {
  const { currentSite } = useSite();

  const Link = () => {
    if (!props.link) return;

    if (props.link.startsWith("http") || props.link.startsWith("mailto")) {
      return (
        <a
          href={props.link}
          target="_blank"
          className={
            currentSite === "hcp"
              ? "btn btn--primary btn--icon cta-btn on-blue"
              : "btn btn--secondary btn--icon cta-btn"
          }
        >
          {props.linkCopy} {props.buttonIcon && <img src={props.buttonIcon} />}
        </a>
      );
    } else {
      return (
        <NavLink
          to={props.link}
          data-path={props.link}
          className={
            currentSite === "hcp"
              ? "btn btn--primary btn--icon cta-btn on-blue"
              : "btn btn--patient btn--icon cta-btn"
          }

        >
          <span dangerouslySetInnerHTML={{__html:props.linkCopy}}></span> {props.buttonIcon && <img src={props.buttonIcon} />}
        </NavLink>
      );
    }
  };

  return (
    <div
      id={props.id}
      className={
        props.styles
          ? `call-to-action call-to-action--${props.styles} ${props.className}`
          : `call-to-action ${props.className}`
      }
    >
      <div className="inner-container">
        {props.icon && <img className="cta-icon" src={props.icon} alt=" " />}

        {props.title && <div className="title">{props.title}</div>}

        <div
          className={`cta-heading ${props.copy ? "" : "no-copy"}`}
          dangerouslySetInnerHTML={{ __html: props.heading }}
        ></div>

        {
          props.copy &&

            <div
                className="copy"
                dangerouslySetInnerHTML={{ __html: props.copy }}
            ></div>
        }

        <Link />
      </div>
    </div>
  );
}
