import React, { useEffect } from "react";
import {
  InitiatingHero,
  CTA,
  References,
  ExternalLink,
} from "../../../components";

import PainManagementImg from "../../../img/pain-management@2x.png"
import FlowChartImg from "../../../img/flow-chart@2x.png"
import DownloadIconWhite from "../../../img/download-icon-white.svg"

import DrinkIcon from "../../../img/icons/drink.svg";
import TongueIcon from "../../../img/icons/tongue.svg";
import WaterIcon from "../../../img/icons/water.svg";
import CheaperIcon from "../../../img/icons/cheaper.svg";
import ClockIcon from "../../../img/icons/clock.svg";
import PillsIcon from "../../../img/icons/pills.svg";
import NoIcon from "../../../img/icons/no.svg";
import TwoIcon from "../../../img/icons/2.svg";
import FourIcon from "../../../img/icons/4.svg";

export default function InitiatingFenhuma() {
  useEffect(() => {
    document.title = "Fenhuma® - Initiating Fenhuma";
  }, []);

  return (
    <main>
      <InitiatingHero />
      <section className="section section--grey section--full-width--left">
          <div className="inner-container">
              <h2>Considerations prior to treatment</h2>

              <p>Prior to initiating treatment with Fenhuma<sup>®</sup>, patients and carers should be referred to the <ExternalLink className="nowrap" link="https://www.medicines.org.uk/emc/files/pil.13972.pdf" title="Patient Information Leaflet"/> and be provided with a copy of the Fenhuma<sup>®</sup> <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2514/Document">Patient & Carer Guide</ExternalLink>. You
                  should ensure patients and carers are aware of and understand the information contained within these
                  documents.</p>

              <p>
                  Prior to starting treatment with Fenhuma<sup>&reg;</sup>,  duration and goals of treatment, and a strategy for ending treatment should be agreed together with the patient, in accordance with pain management guidelines, in order to minimise the risk of addiction and drug withdrawal syndrome.
              </p>

              
              <p>Fenhuma<sup>®</sup> should only be administered to patients who are considered tolerant to their opioid
                  therapy for persistent cancer pain. Patients can be considered opioid tolerant if they take at least:</p>
              
              <ul>
                  <li>60 mg of oral morphine daily</li>
                  <li>25 µg of transdermal fentanyl per hour</li>
                  <li>30 mg of oxycodone daily</li>
                  <li>8 mg of oral hydromorphone daily</li>
                  <li>an equianalgesic dose of another opioid for a week or longer</li>
              </ul>
              
              <p>Fenhuma<sup>®</sup> must not be used in patients under 18 years of age due to a lack of data on safety and
                  efficacy.</p>


          </div>
      </section>

        <section className="section section--contraindications section--white section--full-width">
            <div className="inner-container">
            <h2>
                Contraindications
            </h2>


            <div className="grid grid-2-cols">
                <div>
                    Hypersensitivity to fentanyl citrate, mannitol (E421), silicified microcrystalline cellulose, croscarmellose sodium or magnesium stearate
                </div>
                <div>
                    Opioid-naïve patients
                </div>
                <div>
                    Patients without maintenance opioid therapy as there is increased risk of respiratory depression
                </div>
                <div>
                    Severe respiratory depression or severe obstructive lung conditions
                </div>
                <div>
                    Treatment of acute pain other than breakthrough pain
                </div>
                <div>
                    Patients being treated with medicinal products containing sodium oxybate
                </div>
            </div>

        </div>
        </section>


        <section className="section section--grey section--half-half update-patient-pain-management">
            <div className="inner-container">
                <div className="left">
                    <h2>Update the patient’s pain <span style={{whiteSpace: "nowrap"}}>management plan</span></h2>
                    <p>Patients should already have a defined pain management plan, which supports the management of
                        their persistent cancer pain. If it has been established that the patient is suffering from
                        episodes of breakthrough cancer pain, their pain management plan should be updated to include
                        breakthrough cancer pain management.</p>
                    <p>It is important to gain a thorough understanding of the patient’s experience of breakthrough pain
                        and ensure they understand the benefits and risks associated with treatments specifically
                        targeting breakthrough pain episodes. Discussion points <span className="nowrap">could include:</span></p>
                    <ul>
                        <li>Explaining the frequency of use of breakthrough <span className="nowrap">analgesia</span></li>
                        <li>Defining goals of successful treatment with breakthrough <span className="nowrap">pain therapies</span></li>
                        <li>Discussing the level of side effects associated with breakthrough pain therapies and defining an acceptable level of side effects with the patient</li>
                        <li>Highlighting the risk of addiction associated with <span className="nowrap">opioid-based </span>treatments</li>
                        <li>Stressing the importance of communication throughout the treatment course</li>
                    </ul>
                </div>
                <div className="right">
                    <img src={PainManagementImg} alt=""/>
                </div>
                <div className="bg"></div>
            </div>
        </section>



      <CTA
        title=""
        heading="Looking for more information on managing cancer pain in your patients?"
        copy=""
        link="/hcp/resources/"
        linkCopy="Explore resources"
        className="looking-for-more"
      />

        <section className="section section--white section--full-width--left admin-of-fenhuma">
            <div className="inner-container">
                <h2>Administration of Fenhuma<sup>®</sup></h2>

                <div className="grid grid-2-cols">
                    <div>
                        <div className="row">
                            <div className="icon-container">
                                <div className="icon">
                                    <img src={TongueIcon} alt=""/>
                                </div>

                            </div>
                            <div className="text">
                                <p>Fenhuma<sup>®</sup> sublingual tablets should be administered directly under the
                                    tongue at the deepest part. Fenhuma<sup>®</sup> sublingual tablets should not be
                                    swallowed, but allowed to completely dissolve in the sublingual cavity without
                                    chewing or sucking.</p>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className="row">
                            <div className="icon-container">
                                <div className="icon">
                                    <img src={DrinkIcon} alt=""/>
                                </div>

                            </div>
                            <div className="text">
                                <p>Patients should be advised not to eat or drink anything until the sublingual tablet
                                    has completely dissolved.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="icon-container">
                                <div className="icon">
                                    <img src={WaterIcon} alt=""/>
                                </div>

                            </div>
                            <div className="text">
                                <p>In patients who have a dry mouth, water may be used to moisten the buccal mucosa
                                    before taking Fenhuma<sup>®</sup>.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <h2>Dosing of Fenhuma<sup>®</sup></h2>

                <div className="grid grid-2-cols">
                    <div>
                        <div className="row">
                            <div className="icon-container">
                                <div className="icon">
                                    <img src={CheaperIcon} alt=""/>
                                </div>
                            </div>
                            <div className="text">
                                <p>When initiating any patient onto Fenhuma<sup>®</sup>, you must carry out a dose
                                    titration. The object of dose titration is to identify an optimal maintenance dose
                                    for ongoing treatment of breakthrough pain episodes. This optimal dose should
                                    provide adequate analgesia with an acceptable level of adverse reactions.</p>
                                <p>The optimal dose of Fenhuma<sup>®</sup> will be determined by upward titration, on an
                                    individual patient basis. Several doses are available for use during the dose
                                    titration phase. The initial dose of Fenhuma<sup>®</sup> should be 100 µg, titrating
                                    upwards as necessary through the range of available dosage strengths.</p>
                                <p>Patients should be carefully monitored until an optimal dose is reached.</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="icon-container">
                                <div className="icon">
                                    <img src={ClockIcon} alt=""/>
                                </div>

                            </div>
                            <div className="text">
                                <p>Patients should wait at least 2 hours between treating episodes of breakthrough
                                    pain.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="icon-container">

                                <div className="icon">
                                    <img src={NoIcon} alt=""/>
                                </div>
                            </div>
                            <div className="text">
                                <p>Do not switch patients from another fentanyl-containing product to
                                    Fenhuma<sup>®</sup> at a 1:1 ratio because of different absorption profiles. If
                                    patients are switched from another fentanyl-containing product, a new dose titration
                                    with Fenhuma<sup>®</sup> is required.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="icon-container">

                                <div className="icon">
                                    <img src={PillsIcon} alt=""/>
                                </div>
                            </div>
                            <div className="text">
                                <p>If adequate breakthrough analgesia is achieved at the higher dose, but undesirable effects are
                                    considered unacceptable, an intermediate dose (using the 100 µg dose where
                                    appropriate) may be administered.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="icon-container">

                                <div className="icon">
                                    <img src={FourIcon} alt=""/>
                                </div>
                            </div>
                            <div className="text">
                                <p>Multiple 100/200 µg tablets can be used for any single dose. Do not use more than
                                    four tablets at any one time.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="icon-container">
                                <div className="icon">
                                    <img src={TwoIcon} alt=""/>
                                </div>

                            </div>
                            <div className="text">
                                <p>No more than two doses should be administered for a single episode of breakthrough
                                    pain during the titration phase.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section id="titrate" className="section section--grey section--full-width--left titrate-patient">
            <div className="inner-container">
                <h2>
                    How to dose titrate a patient onto Fenhuma<sup>®</sup>
                </h2>
                <p>
                    All patients must start treatment with a single 100 µg sublingual tablet. <br/> Follow the flowchart below for the Fenhuma<sup>®</sup> titration process.
                </p>

                <img src={FlowChartImg} alt=""/>

                <p aria-hidden="false" className="off-screen-only">
                    Starting dose: 100 µg.
                    Adequate pain relief achieved within 15–30 minutes?
                    Yes.
                    Use this dose for subsequent breakthrough pain episodes.
                    No.
                    Take a second tablet (see table to determine strength of next dose).
                    Increase first tablet to next highest strength for next breakthrough pain episode.
                </p>

                <p>
                    Dose escalation should continue in a stepwise manner until adequate breakthrough analgesia with tolerable adverse reactions is achieved. The strength of the supplemental (second) sublingual tablet is defined in the table below:
                </p>

                <div className="table-wrapper">
                    <table>
                        <tbody>
                        <tr>
                            <td>Strength (µg) of first sublingual tablet per episode of breakthrough pain</td>
                            <td>100</td>
                            <td>200</td>
                            <td>300</td>
                            <td>400</td>
                            <td>600</td>
                            <td>800</td>
                        </tr>
                        <tr>
                            <td>Strength (µg) of supplemental (second) sublingual tablet to be taken 15–30 minutes after the first tablet, if required</td>
                            <td>100</td>
                            <td>100</td>
                            <td>100</td>
                            <td>200</td>
                            <td>200</td>
                            <td>-</td>

                        </tr>
                        </tbody>

                    </table>

                    <div className="mobile-scroller-msg">
                        Scroll to the right 	&rarr;
                    </div>

                </div>


                <p>
                    Boxes of 10 tablets of Fenhuma<sup>®</sup> are available in 100, 200, 300 and 400 µg strengths to support dose titration.
                </p>

                <p>During the titration phase, the patient should continually monitor the effectiveness of Fenhuma® in
                    providing breakthrough pain relief and report the following to their healthcare
                    professional:</p>

                <ul>
                    <li>Did they achieve pain relief at the prescribed dose?</li>
                    <li>How long did it take to achieve pain relief?</li>
                    <li>Was a supplemental tablet needed to achieve pain relief?</li>
                    <li>How long after the first tablet did they take the supplemental tablet?</li>
                </ul>

                <p style={{"margin-top":"3rem"}}>
                    <strong>Use in older people and patients with renal or hepatic impairment:</strong> <br/>
                    Dose titration needs to be approached with particular care and patients should be observed carefully for signs of fentanyl toxicity.
                </p>
            </div>
            
        </section>

        <section className="section section--white section--full-width--left steps-area">
            <div className="inner-container">
                <h2>Continuing treatment once titration is completed</h2>
                <p>
                    The <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2515/Document" title={["Fenhuma",<sup>®</sup>, " Prescriber Guide"]}></ExternalLink> provides support on the diagnosis of breakthrough pain in cancer patients, alongside further guidance on the initiation, administration and titration of Fenhuma<sup>®</sup>.

                    Consult this in conjunction with the full <ExternalLink link="https://www.medicines.org.uk/emc/search?q=fenhuma" title="Summary
                    of Product Characteristics"></ExternalLink> before prescribing Fenhuma<sup>®</sup>.
                </p>

                <div className="white-box">
                    <span className="step">Step one</span>
                    <h3>Maintenance therapy with Fenhuma<sup>®</sup></h3>

                    <p>
                        Once an appropriate dose has been established, which may be more than one tablet, patients should be maintained on this dose and should limit consumption to a maximum of four Fenhuma<sup>®</sup> doses per day.
                    </p>
                    <p>
                        During the maintenance period, patients should wait at least 2 hours before treating another episode of breakthrough pain with Fenhuma<sup>®</sup>.
                    </p>
                </div>

                <div className="white-box">
                    <span className="step">Step two</span>

                    <h3>Adjusting the dose</h3>


                    <p>
                        If the response (analgesia or adverse reactions) to the titrated Fenhuma® dose markedly changes, an adjustment of dose may be necessary to ensure that an optimal dose is maintained.</p>
                    <p>
                        If more than four episodes of breakthrough pain are experienced per day over a period of more than four consecutive days, then the dose of the long-acting opioid used for persistent pain should be re-evaluated. If the long-acting opioid – or dose of long-acting opioid – is changed, the Fenhuma<sup>®</sup> dose should be <span className="nowrap">re-evaluated</span> and <span className="nowrap">re-titrated</span> as necessary to ensure the patient is on an optimal dose.
                    </p>
                    <p>
                        It is imperative that any dose re-titration of any analgesic is monitored by a healthcare professional.
                    </p>
                    <p>
                        In the absence of adequate breakthrough pain relief, the possibility of hyperalgesia, tolerance and progression of underlying disease should be considered.
                    </p>
                </div>

                <div className="white-box">
                    <span className="step">Step three</span>

                    <h3>Discontinuation of Fenhuma<sup>®</sup></h3>


                    <p>Fenhuma<sup>®</sup> should be discontinued immediately if the patient no longer experiences breakthrough pain episodes. The treatment for the persistent background pain should be kept as prescribed.</p>
                    <p>If discontinuation of all opioid therapy is required, the patient must be closely followed by the doctor to avoid the possibility of abrupt withdrawal effects.</p>
                </div>

            </div>
        </section>

        <section className="section section--grey section--half-half discuss-sides">
            <div className="inner-container">
                <div className="left">
                    <div className="blue-box">
                        <p><strong>Want to learn more about the side effects associated with Fenhuma<sup>®</sup>?</strong></p>
                        <p>Download our accessible summary of side effects to find out more, and share this free
                            resource with your patients.</p>

                        <a target="_blank" href="https://kva-glenmark.s3.eu-west-2.amazonaws.com/fenhuma/patient/GLEN24392+-+Fenhuma+patient+website+download+Side+effects_Update_v0.3.pdf" className="btn btn--primary inline-block on-blue">
                            Side effects of Fenhuma<sup>®</sup> <img src={DownloadIconWhite} alt=""/>
                        </a>
                    </div>
                </div>
                <div className="right">
                    <h2>
                        Discuss the side effects of opioid treatment with your patient
                    </h2>

                    <p>Due to the potentially serious undesirable effects that can occur when taking an opioid therapy
                        such as Fenhuma<sup>®</sup>, patients and their carers should be made fully aware of the importance of
                        taking Fenhuma<sup>®</sup> correctly and should be advised on what action to take should symptoms of
                        overdose occur.</p>
                    <p>Before Fenhuma<sup>®</sup> therapy is initiated, it is important that the patient's long-acting
                        opioid treatment used to control their persistent pain has been stabilised.</p>
                    <p>For all patients, prolonged use of this product may lead to drug dependence (addiction), even at
                        therapeutic doses. A higher dose and longer duration of opioid treatment can increase the risk of developing Opioid Use Disorder. The risks are increased in individuals with current or history of substance
                        misuse disorder (including alcohol misuse) or mental health disorder (e.g., major
                        depression).</p>
                    <p>
                        Before initiating treatment with Fenhuma<sup>&reg;</sup> and during the treatment, the patient should be informed about the risks and signs of Opioid Use Disorder. Patients should be advised to contact their physician if these signs occur.
                    </p>
                    <p>Additional support and monitoring may be necessary when prescribing for patients at risk of
                        opioid misuse.
                        Please see Section 4.4 of the <ExternalLink link="https://www.medicines.org.uk/emc/search?q=fenhuma" title="Summary of Product Characteristics" /> for full details of all special
                        warnings and precautions before prescribing.</p>

                </div>
            </div>
        </section>
      <References>
        <ol>
          <li>

              Fenhuma Summary of Product Characteristics (SmPC). Available at: {" "}
            <ExternalLink
              link="https://www.medicines.org.uk/emc/search?q=fenhuma"
              title="https://www.medicines.org.uk/emc/search?q=fenhuma"
            />
          </li>

        </ol>
      </References>
    </main>
  );
}
