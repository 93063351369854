import { useState, useEffect } from "react";
import useEventListener from "./useEventListener";

export default function useSize(ref) {
  const [size, setSize] = useState({});

  useEffect(() => {
    getSize();
  }, []);

  useEventListener("resize", () => {
    getSize();
  });

  const getSize = () => {
    if (ref.current == null) return;
    setSize(ref.current.getBoundingClientRect());
  };

  return size;
}
