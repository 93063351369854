import React, { useEffect, useState, useRef, useCallback } from "react";
import { AssessmentReportPDF } from "../resources";
import { Footer } from "../components";
import * as pdfjs from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

export default function PAR() {
  const canvasRef = useRef();
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [pdfRef, setPdfRef] = useState();
  const parentRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);

  const renderPage = useCallback(
    (pageNum, pdf = pdfRef) => {
      pdf &&
        pdf.getPage(pageNum).then(function (page) {
          var viewport = page.getViewport({ scale: 1.2 });
          const canvas = document.createElement("canvas");

          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,
          };
          page.render(renderContext);

          parentRef.current.appendChild(canvas);
        });
    },
    [pdfRef]
  );

  useEffect(() => {
    if (pdfRef) {
      for (var i = 1; i <= pdfRef.numPages; i++) {
        renderPage(i, pdfRef);
      }
    }
  }, [pdfRef, currentPage, renderPage]);

  useEffect(() => {
    const loadingTask = pdfjs.getDocument(AssessmentReportPDF);
    loadingTask.promise.then(
      (loadedPdf) => {
        setPdfRef(loadedPdf);
      },
      function (reason) {
        console.error(reason);
      }
    );
  }, [AssessmentReportPDF]);

  return (
    <>
      <main className="single-par">
        <section className="section section--grey section--full-width section--report">
          <div className="container">
            <div className="pdf-report" ref={parentRef}>
              <canvas ref={canvasRef}></canvas>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
