import React, { useEffect, useRef } from "react";
import {ExternalLink, Image} from "../../../components";
import { NavLink } from "react-router-dom";
import CouplePNG from "../../../img/couple.png";
import GreenCircleJPG from "../../../img/bg-circles.png";
import GreenCircleWEBP from "../../../img/bg-circles.webp";
import useSize from "../../../hooks/useSize";
import { greenBackground } from "../../../utilities/greenBackground";

export default function SectionConsiderations() {
  const imageRef = useRef();
  const backgroundRef = useRef();
  const size = useSize(imageRef);



  useEffect(() => {
    if (typeof size.width !== "undefined")
      greenBackground(backgroundRef, size, "right");
  }, [size]);

  return (
    <section className="section section--grey section--full-width--left section--considerations">
      <div className="inner-container inner-container--large breakthrough-pain">
        <div className="left">
          <h2>
            Considerations for your patients when prescribing Fenhuma<sup>&reg;</sup>
          </h2>


          <p><strong>Before starting Fenhuma<sup>®</sup></strong></p>

          <p>
            Before starting treatment with Fenhuma<sup>&reg;</sup>,  duration and goals of treatment, and a strategy for ending treatment should be agreed together with the patient, in accordance with pain management guidelines, in order to minimise the risk of addiction and drug withdrawal syndrome.<sup>1</sup>
          </p>
          <p>
            Before and during treatment the patient should also be informed about the risks and signs of Opioid Use Disorder OUD. Patients should be advised to contact their physician if these signs occur<sup>1</sup>
          </p>
          <p>
            Prior to prescribing, attention should be given to whether the patient is suitable to take
            Fenhuma<sup>®</sup>. Factors include their ability to understand and carefully follow dosing instructions,
            and whether they are at increased risk of addiction or overdose.
          </p>

          <p style={{"margin-top": "2.5rem"}}>
            <strong>During Fenhuma<sup>®</sup> treatment</strong>
          </p>

          <p>
            Fenhuma<sup>®</sup> should only be administered to patients who are considered tolerant to their opioid
            therapy for persistent cancer pain. Patients can be considered opioid tolerant if they take at
            least:<sup>1</sup>
          </p>


          <ul>
            <li>60 mg of oral morphine daily</li>
            <li>25 µg of transdermal fentanyl per hour</li>
            <li>30 mg of oxycodone daily</li>
            <li>8 mg of oral hydromorphone daily</li>
            <li>An equianalgesic dose of another opioid for a week or longer</li>
          </ul>

          <p>
            Fenhuma<sup>®</sup> must not be used in patients under 18 years of age due to a lack of data on safety and
            efficacy.
          </p>

          <p>
            For all patients, prolonged use of this product may lead to drug dependence, even at therapeutic doses. A higher dose and longer duration of opioid treatment can increase the risk of developing Opioid Use Disorder. The
            risks are increased in individuals with current or past history of substance misuse disorder (including
            alcohol misuse) or mental health disorder (e.g., major depression). Additional support and monitoring may be
            necessary when prescribing for patients at risk of opioid misuse.<sup>1</sup>
          </p>

          <p>
            During treatment, there should be frequent contact between the physician and the patient to evaluate the need for continued treatment, consider discontinuation and to adjust dosages if needed. In the absence of adequate pain control, the possibility of hyperalgesia, tolerance and progression of underlying disease should be considered. Fenhuma<sup>&reg;</sup> should not be used longer than necessary.<sup>1</sup>
          </p>

          <p>
            Due to the potentially serious undesirable effects that can occur when taking an opioid analgesic therapy
            such as Fenhuma<sup>®</sup>, patients and their carers should be made fully aware of the importance of
            taking Fenhuma<sup>®</sup> correctly and what action to take should symptoms of overdose occur.<sup>1</sup>
          </p>

          <p style={{"margin-top": "2.5rem"}}>
            <strong>Patients with uncontrolled pain:<sup>4</sup></strong>
          </p>

          <p>
            If, after titration, patients do not experience breakthrough pain relief, they should first be reassessed so
            that their breakthrough pain management strategy can be reviewed and modified as appropriate.
          </p>

          <p>
            Following continued monitoring, patients who continue to receive inadequate breakthrough pain relief should
            be referred to a pain or palliative care specialist.
          </p>


          <p>
            For guidance on stopping Fenhuma<sup>®</sup> or switching to an alternative transmucosal fentanyl
            formulation, please see the <ExternalLink link="https://www.medicines.org.uk/emc/rmm/2515/Document"
                                                      title={["Fenhuma", <sup>®</sup>, " Prescriber Guide"]}/>.
          </p>

        </div>
      </div>
    </section>
  );
}
