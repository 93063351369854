import { useState, useEffect, useLayoutEffect, useContext } from "react";
import { AppContext } from "../App";

export default function useJobCode() {
  const { location } = useContext(AppContext);
  const [popupVisible, setPopupVisible] = useState(false);
  const [jobCode, setJobCode] = useState("NP-UK-Bun-0004");

  useLayoutEffect(() => {
    const hcpPopup = document.querySelector(".hcp-popup-header");

    if (hcpPopup) {
      setPopupVisible(true);
    } else {
      setPopupVisible(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (popupVisible) {
      setJobCode("NP-UK-FENH-0015");
    } else {
      switch (location.pathname) {
        case "/hcp/":
        case "/hcp":
          setJobCode("PP-UK-FENH-0047");
          break;
        case "/par/":
          setJobCode("NP-UK-Bun-0014");
          break;
        case "/hcp/initiating-fenhuma":
          setJobCode("PP-UK-FENH-0046");
          break;
        case "/hcp/resources":
          setJobCode("PP-UK-FENH-0048");
          break;
        case "/hcp/contact-us":
          setJobCode("PP-UK-FENH-0045");
          break;
        case "/hcp/pi":
          setJobCode("PP-UK-FENH-0044");
          break;
        case "/patient/":
        case "/patient":
          setJobCode("NP-UK-FENH-0016");
          break;
        case "/patient/taking-fenhuma":
          setJobCode("NP-UK-FENH-0019");
          break;
        case "/patient/side-effects":
          setJobCode("NP-UK-FENH-0017");
          break;
        case "/patient/useful-resources":
          setJobCode("NP-UK-FENH-0018");
          break;
        case "/patient/why-have-i-been-prescribed-fenhuma":
          setJobCode("NP-UK-FENH-0020");
          break;
        default:
          setJobCode("");
          break;
      }
    }
  }, [popupVisible, location.pathname]);

  return jobCode;
}
